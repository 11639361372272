import { call, put, takeEvery } from "redux-saga/effects"
import { dayoffApi } from "../../api/dayoffApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { dayoffActionTypeIds, DayoffDeleteStartAction, DayoffLoadStartAction, DayoffSaveStartAction } from "../action-types/dayoffActionTypes"
import { deleteDayoffError, deleteDayoffSuccess, loadDayoffError, loadDayoffSuccess, saveDayoffError, saveDayoffSuccess } from "../actions/dayoffActions"
export function* DayoffSaga(): any {
    yield takeEvery(dayoffActionTypeIds.DAYOFF_LOAD_START, loadDayoffStartWorker)
    yield takeEvery(dayoffActionTypeIds.DAYOFF_SAVE_START, saveDayoffStartWorker)
    yield takeEvery(dayoffActionTypeIds.DAYOFF_DELETE_START, deleteDayoffStartWorker)
}
function* loadDayoffStartWorker(filter: DayoffLoadStartAction) {
    const res: IBaseResponeModel = yield call(dayoffApi.select_all, filter.payload)
    if (res.is_success) {
        yield put(loadDayoffSuccess(res.data))
    } else {
        yield put(loadDayoffError(res.message || ""))
    }
}
function* saveDayoffStartWorker(data: DayoffSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(dayoffApi.Update, data.payload)
    } else {
        res = yield call(dayoffApi.Insert, data.payload)
    }
    if (res.is_success) {
        yield put(saveDayoffSuccess(data.payload))
    } else {
        yield put(saveDayoffError(res.message || ""))
    }
}
function* deleteDayoffStartWorker(data: DayoffDeleteStartAction) {
    const res: IBaseResponeModel = yield call(dayoffApi.Delete, data.payload)
    if (res.is_success) {
        yield put(deleteDayoffSuccess(data.payload))
    } else {
        yield put(deleteDayoffError(res.message || ""))
    }
}