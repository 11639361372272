import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { NhomKhoanNopActionTypeIds, NhomKhoanNopActionTypes } from "../action-types/nhomKhoanNopActionTypes";
import { NhomKhoanNopPageState } from "../page-state-model/nhomKhoanNopPageState";
const initialState: NhomKhoanNopPageState = {
    sf_nhomkhoannops: [],
    sf_nhomkhoannop_selected_ids: [],
    status: PageBaseStatus.is_completed
}
export const nhomKhoanNopReducer = (state: NhomKhoanNopPageState = initialState, action: NhomKhoanNopActionTypes): NhomKhoanNopPageState => {
    switch (action.type) {
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_nhomkhoannops: action.payload
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANOP_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_nhomkhoannop_editing: action.payload
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANOP_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                // sf_hinhthucmiengiam_editing: undefined
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: action.payload
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công nhóm khoản nộp ${action.payload.ma_nhom}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_nhomkhoannop_editing: undefined,
                is_show_detail_modal: false
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case NhomKhoanNopActionTypeIds.NHOMKHOANNOP_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_nhomkhoannop_selected_ids: action.payload
            }
        default:
            return state;
    }
}