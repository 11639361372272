import { localStorageHelper } from "../../helpers/localStorage";
import { CommonActionTypeIds, CommonActionTypes } from "../action-types/commonActionTypes";

export interface CommonState {
    dm_truong_selected_id: number
    nam_hoc: string,
    dm_coso_id: number,
    is_show_combobox_truong: boolean,
    is_show_combobox_namhoc: boolean
}
const initialState: CommonState = {
    dm_truong_selected_id: localStorage.dm_truong_selected_id ? parseInt(localStorage.dm_truong_selected_id) : 0,
    dm_coso_id: localStorage.dm_coso_id ? parseInt(localStorage.dm_coso_id) : 1,
    nam_hoc: localStorageHelper.getNamHoc(),
    
    is_show_combobox_namhoc: true,
    is_show_combobox_truong: false
}
const commonReducer = (state: CommonState = initialState, action: CommonActionTypes): CommonState => {
    switch (action.type) {
        case CommonActionTypeIds.HEADER_COMBOBOX_TRUONG_SHOW:
            return {
                ...state,
                is_show_combobox_truong: true
            }
        case CommonActionTypeIds.HEADER_COMBOBOX_TRUONG_CHANGED:
            localStorage.dm_truong_selected_id = action.payload;
            return {
                ...state,
                dm_truong_selected_id: action.payload,
            }
        case CommonActionTypeIds.HEADER_COMBOBOX_COSO_CHANGED:
            localStorage.dm_coso_id = action.payload;
            return {
                ...state,
                dm_coso_id: action.payload,
            }
        case CommonActionTypeIds.HEADER_COMBOBOX_TRUONG_CLOSE:
            return {
                ...state,
                is_show_combobox_truong: false
            }
        case CommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_SHOW:
            return {
                ...state,
                is_show_combobox_namhoc: true
            }
        case CommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_CHANGED:
            localStorageHelper.setNamHoc(action.payload)
            return {
                ...state,
                nam_hoc: action.payload
            }
        case CommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_CLOSE:
            return {
                ...state,
                is_show_combobox_namhoc: false
            }
        default:
            return state;
    }
}
export { commonReducer };

