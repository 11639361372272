import { sf_dayoff_delete_request } from "../../models/request/dayoff/sf_dayoff_delete_request"
import { sf_dayoff_model } from "../../models/request/dayoff/sf_dayoff_model"
import { dayoff_detail_view_model } from "../../models/response/dayoff/dayoff_detail_view_model"
import { sf_dayoff } from "../../models/response/dayoff/sf_dayoff"

export enum dayoffActionTypeIds {
    DAYOFF_LOAD_START = "DAYOFF_LOAD_START",
    DAYOFF_LOAD_SUCCESS = "DAYOFF_LOAD_SUCCESS",
    DAYOFF_LOAD_ERROR = "DAYOFF_LOAD_ERROR",

    DAYOFF_SHOW_DETAIL_MODAL = "DAYOFF_SHOW_DETAIL_MODAL",
    DAYOFF_CLOSE_DETAIL_MODAL = "DAYOFF_CLOSE_DETAIL_MODAL",

    DAYOFF_SHOW_DELETE_CONFIRM = "DAYOFF_SHOW_DELETE_CONFIRM",
    DAYOFF_CLOSE_DELETE_CONFIRM = "DAYOFF_CLOSE_DELETE_CONFIRM",

    DAYOFF_SAVE_START = "DAYOFF_SAVE_START",
    DAYOFF_SAVE_SUCCESS = "DAYOFF_SAVE_SUCCESS",
    DAYOFF_SAVE_ERROR = "DAYOFF_SAVE_ERROR",

    DAYOFF_DELETE_START = "DAYOFF_DELETE_START",
    DAYOFF_DELETE_SUCCESSS = "DAYOFF_DELETE_SUCCESSS",
    DAYOFF_DELETE_ERROR = "DAYOFF_DELETE_ERROR",

    DAYOFF_CHANGE_SELECTED_IDS = "DAYOFF_CHANGE_SELECTED_IDS",
}

export interface DayoffLoadStartAction {
    type: dayoffActionTypeIds.DAYOFF_LOAD_START,
    payload: number
}
export interface DayoffLoadSuccessAction {
    type: dayoffActionTypeIds.DAYOFF_LOAD_SUCCESS,
    payload: dayoff_detail_view_model[]
}
export interface DayoffLoadErrorAction {
    type: dayoffActionTypeIds.DAYOFF_LOAD_ERROR,
    payload: string
}

export interface DayoffShowDetailModalAction {
    type: dayoffActionTypeIds.DAYOFF_SHOW_DETAIL_MODAL,
    payload?: dayoff_detail_view_model
}
export interface DayoffCloseDetailModalAction {
    type: dayoffActionTypeIds.DAYOFF_CLOSE_DETAIL_MODAL,
}
export interface DayoffShowDeleteConfirmAction {
    type: dayoffActionTypeIds.DAYOFF_SHOW_DELETE_CONFIRM,
}
export interface DayoffCloseDeleteConfirmAction {
    type: dayoffActionTypeIds.DAYOFF_CLOSE_DELETE_CONFIRM,
}

export interface DayoffSaveStartAction {
    type: dayoffActionTypeIds.DAYOFF_SAVE_START,
    payload: dayoff_detail_view_model
}
export interface DayoffSaveSuccessAction {
    type: dayoffActionTypeIds.DAYOFF_SAVE_SUCCESS,
    payload: dayoff_detail_view_model
}
export interface DayoffSaveErrorAction {
    type: dayoffActionTypeIds.DAYOFF_SAVE_ERROR,
    payload: string
}
export interface DayoffDeleteStartAction {
    type: dayoffActionTypeIds.DAYOFF_DELETE_START,
    payload: sf_dayoff_delete_request
}
export interface DayoffDeleteSuccessAction {
    type: dayoffActionTypeIds.DAYOFF_DELETE_SUCCESSS,
    payload: sf_dayoff_delete_request
}
export interface DayoffDeleteErrorAction {
    type: dayoffActionTypeIds.DAYOFF_DELETE_ERROR,
    payload: string
}

export interface DayoffChangeSelectedIdsAction {
    type: dayoffActionTypeIds.DAYOFF_CHANGE_SELECTED_IDS,
    payload: number[]
}

export interface DayoffChangeSelectedIdsAction {
    type: dayoffActionTypeIds.DAYOFF_CHANGE_SELECTED_IDS,
    payload: number[]
}


export type DayoffActionTypes =
    DayoffLoadStartAction | DayoffLoadSuccessAction | DayoffLoadErrorAction |
    DayoffShowDetailModalAction | DayoffCloseDetailModalAction |
    DayoffShowDeleteConfirmAction | DayoffCloseDeleteConfirmAction |
    DayoffSaveStartAction | DayoffSaveSuccessAction | DayoffSaveErrorAction |
    DayoffDeleteStartAction | DayoffDeleteSuccessAction | DayoffDeleteErrorAction |
    DayoffChangeSelectedIdsAction