import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { TBTPActionTypeIds, TBTPActionTypes } from "../action-types/tbtpActionTypes";
import { TBTPPageState } from "../page-state-model/tbtpPageState";
const initialState: TBTPPageState = {
    sf_tbtp_viewmodels: [],
    sf_tbtp_selected_ids: [],
    status: PageBaseStatus.is_completed,
    nam_hoc: localStorageHelper.getNamHoc(),
    dm_he_id: localStorageHelper.getDmHeId(),
    dm_khoi_id: localStorageHelper.getDmKhoiId(),
    dm_truong_id: localStorageHelper.getDmTruongId()
}
export const tbtpReducer = (state: TBTPPageState = initialState, action: TBTPActionTypes): TBTPPageState => {
    switch (action.type) {
        case TBTPActionTypeIds.TBTP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TBTPActionTypeIds.TBTP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_tbtp_viewmodels: action.payload
            }
        case TBTPActionTypeIds.TBTP_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TBTPActionTypeIds.TBTP_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true
            }
        case TBTPActionTypeIds.TBTP_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
            }
        case TBTPActionTypeIds.TBTP_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case TBTPActionTypeIds.TBTP_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case TBTPActionTypeIds.TBTP_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case TBTPActionTypeIds.TBTP_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công thông báo thu phí`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case TBTPActionTypeIds.TBTP_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case TBTPActionTypeIds.TBTP_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case TBTPActionTypeIds.TBTP_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} thông báo thu phí`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case TBTPActionTypeIds.TBTP_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TBTPActionTypeIds.TBTP_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_tbtp_selected_ids: action.payload
            }
        case TBTPActionTypeIds.TBTP_NAMHOC_CHANGED:
            return {
                ...state,
                nam_hoc: action.payload
            }
        case TBTPActionTypeIds.TBTP_HE_CHANGED:
            return {
                ...state,
                dm_he_id: action.payload
            }
        case TBTPActionTypeIds.TBTP_KHOI_CHANGED:
            return {
                ...state,
                dm_khoi_id: action.payload,
                dm_he_id: 0
            }
        case TBTPActionTypeIds.TBTP_TRUONG_CHANGED:
            return {
                ...state,
                dm_truong_id: action.payload,
                dm_khoi_id: 0,
                dm_he_id: 0
            }
        case TBTPActionTypeIds.TBTP_SHOW_EDIT:
            return {
                ...state,
                is_show_edit_modal: true,
                sf_tbtp_editing: action.payload
            }
        case TBTPActionTypeIds.TBTP_CLOSE_EDIT:
            return {
                ...state,
                is_show_edit_modal: false,
            }
        case TBTPActionTypeIds.TBTP_SHOW_PDF_CONFIRM:
            return {
                ...state,
                is_show_pdf_confirm: true,
            }
        case TBTPActionTypeIds.TBTP_CLOSE_PDF_CONFIRM:
            return {
                ...state,
                is_show_pdf_confirm: false,
            }
        case TBTPActionTypeIds.TBTP_CREATE_PDF_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving,
            }
        case TBTPActionTypeIds.TBTP_CREATE_PDF_SUCCESS:
            showNotify({ message: "Đã tạo thành công", type: "success" })
            return {
                ...state,
                is_show_pdf_confirm: false,
                status: PageBaseStatus.is_need_reload
            }
        case TBTPActionTypeIds.TBTP_CREATE_PDF_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        case TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_SHOW_CONFIRM:
            return {
                ...state,
                is_show_auto_hachtoan_confirm: true
            }
        case TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_CLOSE_CONFIRM:
            return {
                ...state,
                is_show_auto_hachtoan_confirm: false,
            }
        case TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving,
            }
        case TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_SUCCESS:
            showNotify({ message: "Đã thêm thành công", type: "success" })

            return {
                ...state,
                is_show_auto_hachtoan_confirm: false,
                status: PageBaseStatus.is_need_reload
            }
        case TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_ERRROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }

        case TBTPActionTypeIds.TBTP_SEND_SHOW_CONFIRM:
            return {
                ...state,
                is_show_send_confirm: true
            }
        case TBTPActionTypeIds.TBTP_SEND_CLOSE_CONFIRM:
            return {
                ...state,
                is_show_send_confirm: false,
            }
        case TBTPActionTypeIds.TBTP_SEND_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving,
            }
        case TBTPActionTypeIds.TBTP_SEND_SUCCESS:
            showNotify({ message: "Đã gửi thành công", type: "success" })
            return {
                ...state,
                is_show_send_confirm: false,
                status: PageBaseStatus.is_need_reload
            }
        case TBTPActionTypeIds.TBTP_SEND_ERR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        case TBTPActionTypeIds.TBTP_SHOW_DOTGUI_DETAIL_MODAL:
            return {
                ...state,
                is_show_dot_gui_detail_modal: true
            }
        case TBTPActionTypeIds.TBTP_CLOSE_DOTGUI_DETAIL_MODAL:
            return {
                ...state,
                is_show_dot_gui_detail_modal: false,
            }

        case TBTPActionTypeIds.TBTP_SHOW_PAYMENT_CONFIRM:
            return {
                ...state,
                is_show_payment_confirm: true,
            }
        case TBTPActionTypeIds.TBTP_CLOSE_PAYMENT_CONFIRM:
            return {
                ...state,
                is_show_payment_confirm: false,
            }
        case TBTPActionTypeIds.TBTP_CREATE_PAYMENT_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving,
            }
        case TBTPActionTypeIds.TBTP_CREATE_PAYMENT_SUCCESS:
            showNotify({ message: "Đã tạo thành công", type: "success" })
            return {
                ...state,
                is_show_payment_confirm: false,
                status: PageBaseStatus.is_need_reload
            }
        case TBTPActionTypeIds.TBTP_CREATE_PAYMENT_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        case TBTPActionTypeIds.TBTP_SHOW_PRINT:
            return {
                ...state,
                is_show_print_confirm: true
            }
        case TBTPActionTypeIds.TBTP_CLOSE_PRINT:
            return {
                ...state,
                is_show_print_confirm: false,
            }

        default:
            return state;
    }
}