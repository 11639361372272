import { sf_hinhthucthanhtoan } from "../../models/response/hinh-thuc-thanh-toan/sf_hinhthucthanhtoan"

export enum HinhThucThanhToanActionTypeIds {
    HINHTHUCTHANHTOAN_LOAD_START = "HINHTHUCTHANHTOAN_LOAD_START",
    HINHTHUCTHANHTOAN_LOAD_SUCCESS = "HINHTHUCTHANHTOAN_LOAD_SUCCESS",
    HINHTHUCTHANHTOAN_LOAD_ERROR = "HINHTHUCTHANHTOAN_LOAD_ERROR",

    HINHTHUCTHANHTOAN_SHOW_DETAIL_MODAL = "HINHTHUCTHANHTOAN_SHOW_DETAIL_MODAL",
    HINHTHUCTHANHTOAN_CLOSE_DETAIL_MODAL = "HINHTHUCTHANHTOAN_CLOSE_DETAIL_MODAL",

    HINHTHUCTHANHTOAN_SHOW_DELETE_CONFIRM = "HINHTHUCTHANHTOAN_SHOW_DELETE_CONFIRM",
    HINHTHUCTHANHTOAN_CLOSE_DELETE_CONFIRM = "HINHTHUCTHANHTOAN_CLOSE_DELETE_CONFIRM",

    HINHTHUCTHANHTOAN_SAVE_START = "HINHTHUCTHANHTOAN_SAVE_START",
    HINHTHUCTHANHTOAN_SAVE_SUCCESS = "HINHTHUCTHANHTOAN_SAVE_SUCCESS",
    HINHTHUCTHANHTOAN_SAVE_ERROR = "HINHTHUCTHANHTOAN_SAVE_ERROR",

    HINHTHUCTHANHTOAN_DELETE_START = "HINHTHUCTHANHTOAN_DELETE_START",
    HINHTHUCTHANHTOAN_DELETE_SUCCESSS = "HINHTHUCTHANHTOAN_DELETE_SUCCESSS",
    HINHTHUCTHANHTOAN_DELETE_ERROR = "HINHTHUCTHANHTOAN_DELETE_ERROR",

    HINHTHUCTHANHTOAN_CHANGE_SELECTED_IDS = "HINHTHUCTHANHTOAN_CHANGE_SELECTED_IDS",
}

export interface HinhThucThanhToanLoadStartAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_START,
}
export interface HinhThucThanhToanLoadSuccessAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_SUCCESS,
    payload: sf_hinhthucthanhtoan[]
}
export interface HinhThucThanhToanLoadErrorAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_ERROR,
    payload: string
}

export interface HinhThucThanhToanShowDetailModalAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SHOW_DETAIL_MODAL,
    payload?: sf_hinhthucthanhtoan
}
export interface HinhThucThanhToanCloseDetailModalAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CLOSE_DETAIL_MODAL,
}
export interface HinhThucThanhToanShowDeleteConfirmAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SHOW_DELETE_CONFIRM,
}
export interface HinhThucThanhToanCloseDeleteConfirmAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CLOSE_DELETE_CONFIRM,
}

export interface HinhThucThanhToanSaveStartAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_START,
    payload: sf_hinhthucthanhtoan
}
export interface HinhThucThanhToanSaveSuccessAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_SUCCESS,
    payload: sf_hinhthucthanhtoan
}
export interface HinhThucThanhToanSaveErrorAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_ERROR,
    payload: string
}
export interface HinhThucThanhToanDeleteStartAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_START,
    payload: number[]
}
export interface HinhThucThanhToanDeleteSuccessAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_SUCCESSS,
    payload: number[]
}
export interface HinhThucThanhToanDeleteErrorAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_ERROR,
    payload: string
}

export interface HinhThucThanhToanChangeSelectedIdsAction {
    type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CHANGE_SELECTED_IDS,
    payload: number[]
}


export type HinhThucThanhToanActionTypes =
    HinhThucThanhToanLoadStartAction | HinhThucThanhToanLoadSuccessAction | HinhThucThanhToanLoadErrorAction |
    HinhThucThanhToanShowDetailModalAction | HinhThucThanhToanCloseDetailModalAction |
    HinhThucThanhToanShowDeleteConfirmAction | HinhThucThanhToanCloseDeleteConfirmAction |
    HinhThucThanhToanSaveStartAction | HinhThucThanhToanSaveSuccessAction | HinhThucThanhToanSaveErrorAction |
    HinhThucThanhToanDeleteStartAction | HinhThucThanhToanDeleteSuccessAction | HinhThucThanhToanDeleteErrorAction |
    HinhThucThanhToanChangeSelectedIdsAction