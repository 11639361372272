import { NamHocTruongKhoiHeFilter } from "../models/namHocTruongKhoiHeFilter";
import { sf_dexuatmiengiam_approve_rq } from "../models/request/gan-mien-giam/sf_dexuatmiengiam_approve_rq";
import { sf_dexuatmiengiam_changed_rq } from "../models/request/gan-mien-giam/sf_dexuatmiengiam_changed_rq";
import { sf_hachtoan_congno_import_rq } from "../models/request/hach-toan-cong-no/sf_hachtoan_congno_import_rq";
import { apiClient } from "./apiClient";

export const SF_DEXUATMIENGIAM_SELECT = "sf_dexuatmiengiam/select";
export const SF_DEXUATMIENGIAM_CHANGED = "sf_dexuatmiengiam/changed";
export const SF_DEXUATMIENGIAM_APPROVE = "sf_dexuatmiengiam/approve_all";

export const ganMienGiamApi = {
    select: (rq: NamHocTruongKhoiHeFilter) => {
        return apiClient.post(`${SF_DEXUATMIENGIAM_SELECT}`, rq)
    },
    changed: (rq: sf_dexuatmiengiam_changed_rq) => {
        return apiClient.post(`${SF_DEXUATMIENGIAM_CHANGED}`, rq)
    },
    approve: (rq: sf_dexuatmiengiam_approve_rq) => {
        return apiClient.post(`${SF_DEXUATMIENGIAM_APPROVE}`, rq)
    },
    getSheets: (file_name_uploaded: string) => apiClient.post(`sf_dexuatmiengiam/get_sheets?file_name_uploaded=${file_name_uploaded}`),
    readFiles: (rq: sf_hachtoan_congno_import_rq) => apiClient.post(`sf_dexuatmiengiam/read_file`, rq),
    import: (rq: sf_hachtoan_congno_import_rq) => apiClient.post(`sf_dexuatmiengiam/import`, rq),

}