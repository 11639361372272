import { sf_mucphaithu, sf_mucphaithu_viewmodel } from './../../models/response/thu-phi/sf_mucphaithu';
import { ts_hocsinh_viewmodel } from './../../models/response/hocsinh/ts_hocsinh';
import { ISalseSetSelectByStudentRequest } from '../../models/request/sale-set/SaleSetSelectByStudentRequest';
import { sf_saleset_viewmodel } from '../../models/response/sale-set/sf_saleset';
import { IActionBase } from './IActionBase';
import { sf_mucphaithu_select_byhocsinh_rq } from '../../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq';
import { sf_mucphaithu_insert_rq } from '../../models/request/thu-phi/sf_mucphaithu_insert_from_khoannop_rq';
import { sf_mucphaithu_insert_from_tbtp_rq } from '../../models/request/thu-phi/sf_mucphaithu_insert_from_tbtp_rq';
export enum eHocSinhProfileActionTypeIds {
    SELECT_SALESET_START = "HOCSINH_PROFILE_SELECT_SALESET_START",
    SELECT_SALESET_SUCCESS = "HOCSINH_PROFILE_SELECT_SALESET_SUCCESS",
    SELECT_SALESET_ERROR = "HOCSINH_PROFILE_SELECT_SALESET_ERROR",

    SELECT_HOCSINH_START = "HOCSINH_PROFILE_SELECT_HOCSINH_START",
    SELECT_HOCSINH_SUCCESS = "HOCSINH_PROFILE_SELECT_HOCSINH_SUCCESS",
    SELECT_HOCSINH_ERROR = "HOCSINH_PROFILE_SELECT_HOCSINH_ERROR",

    SELECT_CHUNGTU_START = "HOCSINH_PROFILE_SELECT_CHUNGTU_START",
    SELECT_CHUNGTU_SUCCESS = "HOCSINH_PROFILE_SELECT_CHUNGTU_SUCCESS",
    SELECT_CHUNGTU_ERROR = "HOCSINH_PROFILE_SELECT_CHUNGTU_ERROR",

    SHOW_THUPHI_CHITIET = "HOCSINH_PROFILE_SELECT_SHOW_THUPHI_CHITIET",
    CLOSE_THUPHI_CHITIET = "HOCSINH_PROFILE_SELECT_SHOW_THUPHI_CHITIET",

    SHOW_THUPHI_ADD_MODAL = "HOCSINH_PROFILE_SELECT_SHOW_THUPHI_ADD_MODAL",
    CLOSE_THUPHI_ADD_MODAL = "HOCSINH_PROFILE_SELECT_CLOSE_THUPHI_ADD_MODAL",

    THUPHI_CREATE_FROM_TBTP_START = "HOCSINH_PROFILE_SELECT_THUPHI_CREATE_FROM_TBTP_START",
    THUPHI_CREATE_FROM_TBTP_SUCESS = "HOCSINH_PROFILE_SELECT_THUPHI_CREATE_FROM_TBTP_SUCESS",
    THUPHI_CREATE_FROM_TBTP_ERROR = "HOCSINH_PROFILE_SELECT_THUPHI_CREATE_FROM_TBTP_ERROR",


    THUPHI_CREATE_FROM_KHOANNOP_START = "HOCSINH_PROFILE_SELECT_THUPHI_CREATE_FROM_KHOANNOP_START",
    THUPHI_CREATE_FROM_KHOANNOP_SUCCESS = "HOCSINH_PROFILE_SELECT_THUPHI_CREATE_FROM_KHOANNOP_SUCCESS",
    THUPHI_CREATE_FROM_KHOANNOP_ERROR = "HOCSINH_PROFILE_SELECT_THUPHI_CREATE_FROM_KHOANNOP_ERROR",

    CHANGE_FILTER = "HOCSINH_PROFILE_CHANGE_FILTER"
}

export interface IHocSinhProfileSelectSaleSetStart extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_SALESET_START, ISalseSetSelectByStudentRequest> { }
export interface IHocSinhProfileSelectSaleSetSuccess extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_SALESET_SUCCESS, sf_saleset_viewmodel[]> { }
export interface IHocSinhProfileSelectSaleSetError extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_SALESET_ERROR, string> { }

export interface IHocSinhProfileSelectHocSinhStart extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_HOCSINH_START, sf_mucphaithu_select_byhocsinh_rq> { }
export interface IHocSinhProfileSelectHocSinhSuccess extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_HOCSINH_SUCCESS, ts_hocsinh_viewmodel> { }
export interface IHocSinhProfileSelectHocSinhError extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_HOCSINH_ERROR, string> { }

export interface IHocSinhProfileSelectChungTuStart extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_START, sf_mucphaithu_select_byhocsinh_rq> { }
export interface IHocSinhProfileSelectChungTuSuccess extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_SUCCESS, sf_mucphaithu_viewmodel[]> { }
export interface IHocSinhProfileSelectChungTuError extends IActionBase<eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_ERROR, string> { }

export interface IHocSinhProfileShowThuPhiChiTiet extends IActionBase<eHocSinhProfileActionTypeIds.SHOW_THUPHI_CHITIET, sf_mucphaithu> { }
export interface IHocSinhProfileCloseThuPhiChiTiet extends IActionBase<eHocSinhProfileActionTypeIds.CLOSE_THUPHI_CHITIET, undefined> { }

export interface IHocSinhProfileShowAddModal extends IActionBase<eHocSinhProfileActionTypeIds.SHOW_THUPHI_ADD_MODAL, undefined> { }
export interface IHocSinhProfileCloseAddModal extends IActionBase<eHocSinhProfileActionTypeIds.CLOSE_THUPHI_ADD_MODAL, undefined> { }

export interface IHocSinhProfileCreateThuPhiFromKhoanNopStart extends IActionBase<eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START, sf_mucphaithu_insert_rq> { }
export interface IHocSinhProfileCreateThuPhiFromKhoanNopSuccess extends IActionBase<eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_SUCCESS, undefined> { }
export interface IHocSinhProfileCreateThuPhiFromKhoanNopError extends IActionBase<eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_ERROR, string> { }


export interface IHocSinhProfileCreateThuPhiFromTBPTStart extends IActionBase<eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_START, sf_mucphaithu_insert_from_tbtp_rq> { }
export interface IHocSinhProfileCreateThuPhiFromTBTPSuccess extends IActionBase<eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_SUCESS, undefined> { }
export interface IHocSinhProfileCreateThuPhiFromTBTPError extends IActionBase<eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_ERROR, string> { }

export interface IHocSinhProfileChangeFilter extends IActionBase<eHocSinhProfileActionTypeIds.CHANGE_FILTER, ISalseSetSelectByStudentRequest> { }



export type IHocSinhProfileActionTypes = IHocSinhProfileSelectSaleSetStart | IHocSinhProfileSelectSaleSetSuccess | IHocSinhProfileSelectSaleSetError |
    IHocSinhProfileChangeFilter |
    IHocSinhProfileSelectHocSinhStart | IHocSinhProfileSelectHocSinhSuccess | IHocSinhProfileSelectHocSinhError |
    IHocSinhProfileSelectChungTuStart | IHocSinhProfileSelectChungTuSuccess | IHocSinhProfileSelectChungTuError |
    IHocSinhProfileShowThuPhiChiTiet | IHocSinhProfileCloseThuPhiChiTiet |
    IHocSinhProfileShowAddModal | IHocSinhProfileCloseAddModal |
    IHocSinhProfileCreateThuPhiFromKhoanNopStart | IHocSinhProfileCreateThuPhiFromKhoanNopSuccess | IHocSinhProfileCreateThuPhiFromKhoanNopError |
    IHocSinhProfileCreateThuPhiFromTBPTStart | IHocSinhProfileCreateThuPhiFromTBTPSuccess | IHocSinhProfileCreateThuPhiFromTBTPError