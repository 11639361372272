import { saleSetDichVuReducer } from './saleSetRegisterDichVuReducer';
import { combineReducers } from "redux";
import { accountReducer } from "./accountReducer";
import { authReducer } from "./authReducer";
import { bomChiTietReducer } from "./bomChiTietReducer";
import { BOMReducer } from "./bomReducer";
import { categorySourceReducer } from "./categorySourceReducer";
import { commonReducer } from "./commonReducer";
import { dictionaryReducer } from "./dictionaryReducer";
import { duKienReducer } from './duKienReducer';
import { editTBTPHachToanReducer } from "./edit-tbtp/editTBTPHachToanReducer";
import { editTBTPKhoanNopReducer } from "./edit-tbtp/editTBTPKhoanNopReducer";
import { ganMienGiamReducer } from "./ganMienGiamReducer";
import { hachToanCongNoReducer } from "./hachToanCongNoReducer";
import { hinhThucMienGiamReducer } from "./hinhThucMienGiamReducer";
import { hoanPhiReducer } from './hoanPhiReducer';
import { hoanTraTienAnReducer } from './hoanTraTienAnReducer';
import { hocSinhProfileReducer } from './hocSinhProfileReducer';
import { khoanNopGroupReducer } from "./khoanNopGroupReducer";
import { khoanNopReducer } from "./khoanNopReducer";
import { loaiKhoanNopReducer } from "./loaiKhoanNopReducer";
import { mucThuPhiNgsReducer } from './mucThuPhiNgsReducer';
import { mucThuPhiReducer } from "./mucThuPhiReducer";
import { nhomKhoanNopReducer } from "./nhomKhoanNopReducer";
import { tbtpReducer } from "./tbtpReducer";
import { thuPhiChiTietReducer } from "./thuPhiChiTietReducer";
import { thuPhiReducer } from "./thuPhiReducers";
import { tongHopChiTietReducer } from "./tongHopChiTietReducer";
import { tongHopReducer } from "./tongHopReducer";
import { hinhThucThanhToanReducer } from './hinhThucThanhToanReducer';
import { DayoffReducer } from './dayoffReducer';
import { tongHopHocPhiReducer } from './tongHopHocPhiReducer';
import { dotGuiReducer } from "./dotGuiReducer";
import { dotGuiTBTPReducer } from './dotGuiTBTPReducer';

const reducers = combineReducers({
    auth: authReducer,
    account: accountReducer,
    dictionary: dictionaryReducer,
    common: commonReducer,
    khoanNop: khoanNopReducer,
    loaiKhoanNop: loaiKhoanNopReducer,
    nhomKhoanNop: nhomKhoanNopReducer,
    khoanNopGroup: khoanNopGroupReducer,
    categorySource: categorySourceReducer,
    mucThuPhi: mucThuPhiReducer,
    bom: BOMReducer,
    bomChiTiet: bomChiTietReducer,
    tbtp: tbtpReducer,
    hinhThucMienGiam: hinhThucMienGiamReducer,
    ganMienGiam: ganMienGiamReducer,
    hachToan: hachToanCongNoReducer,
    editTBTPHachToan: editTBTPHachToanReducer,
    editTBTPKhoanNop: editTBTPKhoanNopReducer,
    tongHop: tongHopReducer,
    tongHopChiTiet: tongHopChiTietReducer,
    thuPhi: thuPhiReducer,
    thuPhiChiTiet: thuPhiChiTietReducer,
    mucThuPhiNgs: mucThuPhiNgsReducer,
    duKien: duKienReducer,
    hocSinhProfile: hocSinhProfileReducer,
    hoanTraTienAn: hoanTraTienAnReducer,
    hoanPhi: hoanPhiReducer,
    saleSetDichVu: saleSetDichVuReducer,
    hinhThucThanhToan: hinhThucThanhToanReducer,
    dayoff: DayoffReducer,
    tongHopHocPhi: tongHopHocPhiReducer,
    dotGui: dotGuiReducer,
    dotGuiTBTP: dotGuiTBTPReducer,


})

export default reducers

export type RootState = ReturnType<typeof reducers>