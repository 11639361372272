import { sf_hinhthucthanhtoan } from "../../models/response/hinh-thuc-thanh-toan/sf_hinhthucthanhtoan"
import {
    HinhThucThanhToanActionTypeIds, HinhThucThanhToanChangeSelectedIdsAction, HinhThucThanhToanCloseDeleteConfirmAction, HinhThucThanhToanCloseDetailModalAction, HinhThucThanhToanDeleteErrorAction, HinhThucThanhToanDeleteStartAction, HinhThucThanhToanDeleteSuccessAction, HinhThucThanhToanLoadErrorAction, HinhThucThanhToanLoadStartAction, HinhThucThanhToanLoadSuccessAction, HinhThucThanhToanSaveErrorAction, HinhThucThanhToanSaveStartAction, HinhThucThanhToanSaveSuccessAction, HinhThucThanhToanShowDeleteConfirmAction, HinhThucThanhToanShowDetailModalAction
} from "../action-types/hinhThucThanhToanActionTypes"

export const loadHinhThucThanhToanStart = (): HinhThucThanhToanLoadStartAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_START
    }
}

export const loadHinhThucThanhToanSuccess = (payload: sf_hinhthucthanhtoan[]): HinhThucThanhToanLoadSuccessAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadHinhThucThanhToanError = (payload: string): HinhThucThanhToanLoadErrorAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_ERROR,
        payload: payload
    }
}

export const showHinhThucThanhToanDetailModal = (payload?: sf_hinhthucthanhtoan): HinhThucThanhToanShowDetailModalAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeHinhThucThanhToanDetailModal = (): HinhThucThanhToanCloseDetailModalAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CLOSE_DETAIL_MODAL,
    }
}

export const showHinhThucThanhToanDeleteConfirm = (): HinhThucThanhToanShowDeleteConfirmAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SHOW_DELETE_CONFIRM,
    }
}
export const closeHinhThucThanhToanDeleteConfirm = (): HinhThucThanhToanCloseDeleteConfirmAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CLOSE_DELETE_CONFIRM,
    }
}

export const saveHinhThucThanhToanStart = (payload: sf_hinhthucthanhtoan): HinhThucThanhToanSaveStartAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_START,
        payload: payload
    }
}

export const saveHinhThucThanhToanSuccess = (payload: sf_hinhthucthanhtoan): HinhThucThanhToanSaveSuccessAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveHinhThucThanhToanError = (payload: string): HinhThucThanhToanSaveErrorAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_ERROR,
        payload: payload
    }
}

export const deleteHinhThucThanhToanStart = (payload: number[]): HinhThucThanhToanDeleteStartAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_START,
        payload: payload
    }
}

export const deleteHinhThucThanhToanSuccess = (payload: number[]): HinhThucThanhToanDeleteSuccessAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteHinhThucThanhToanError = (payload: string): HinhThucThanhToanDeleteErrorAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_ERROR,
        payload: payload
    }
}

export const changeHinhThucThanhToanSelectionIds = (payload: number[]): HinhThucThanhToanChangeSelectedIdsAction => {
    return {
        type: HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CHANGE_SELECTED_IDS,
        payload: payload
    }
}

