import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_dayoff_delete_request } from "../models/request/dayoff/sf_dayoff_delete_request";
import { apiClient } from "./apiClient";

export const SF_DAYOFF_SELECT_ALL = "sf_dayoff/select_all"
export const SF_DAYOFF_INSERT = "sf_dayoff/insert";
export const SF_DAYOFF_UPDATE = "sf_dayoff/update";
export const SF_DAYOFF_DELETE = "sf_dayoff/delete";

export const dayoffApi = {
    select_all: (dm_coso_id: number) => apiClient.post(`${SF_DAYOFF_SELECT_ALL}` + '?coso=' + dm_coso_id),
    Insert: (data: any) => apiClient.post(`${SF_DAYOFF_INSERT}`, data),
    Update: (data: any) => apiClient.post(`${SF_DAYOFF_UPDATE}`, data),
    Delete: (data: sf_dayoff_delete_request) => {
        return apiClient.post(`${SF_DAYOFF_DELETE}`, data)
    }
}