import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { sf_dotgui_tbtp, sf_dotgui_tbtp_request, sf_dotgui_tbtp_viewmodel } from "../../models/response/dot-gui/sf_dotgui_tbtp"
export enum DotGuiTBTPActionTypeIds {
    DOTGUITBTP_LOAD_START = "DOTGUITBTP_LOAD_START",
    DOTGUITBTP_LOAD_SUCCESS = "DOTGUITBTP_LOAD_SUCCESS",
    DOTGUITBTP_LOAD_ERROR = "DOTGUITBTP_LOAD_ERROR",

    DOTGUITBTP_SHOW_DETAIL_MODAL = "DOTGUITBTP_SHOW_DETAIL_MODAL",
    DOTGUITBTP_CLOSE_DETAIL_MODAL = "DOTGUITBTP_CLOSE_DETAIL_MODAL",

    DOTGUITBTP_SHOW_DELETE_CONFIRM = "DOTGUITBTP_SHOW_DELETE_CONFIRM",
    DOTGUITBTP_CLOSE_DELETE_CONFIRM = "DOTGUITBTP_CLOSE_DELETE_CONFIRM",

    DOTGUITBTP_SAVE_START = "DOTGUITBTP_SAVE_START",
    DOTGUITBTP_SAVE_SUCCESS = "DOTGUITBTP_SAVE_SUCCESS",
    DOTGUITBTP_SAVE_ERROR = "DOTGUITBTP_SAVE_ERROR",

    DOTGUITBTP_DELETE_START = "DOTGUITBTP_DELETE_START",
    DOTGUITBTP_DELETE_SUCCESSS = "DOTGUITBTP_DELETE_SUCCESSS",
    DOTGUITBTP_DELETE_ERROR = "DOTGUITBTP_DELETE_ERROR",

    DOTGUITBTP_CHANGE_SELECTED_IDS = "DOTGUITBTP_CHANGE_SELECTED_IDS",

    DOTGUITBTP_CHANGE_FILTER = "DOTGUITBTP_CHANGE_FILTER",

    DOTGUITBTP_SHOW_IMPORT_MODEL = "DOTGUITBTP_SHOW_IMPORT_MODEL",
    DOTGUITBTP_CLOSE_IMPORT_MODEL = "DOTGUITBTP_CLOSE_IMPORT_MODEL",
    
    DOTGUITBTP_TRUONG_CHANGED = "DOTGUITBTP_TRUONG_CHANGED"

}

export interface DotGuiTBTPLoadStartAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_START,
    payload: NamHocTruongKhoiHeFilter
}
export interface DotGuiTBTPLoadSuccessAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_SUCCESS,
    payload: sf_dotgui_tbtp_viewmodel[]
}
export interface DotGuiTBTPLoadErrorAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_ERROR,
    payload: string
}

export interface DotGuiTBTPShowDetailModalAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_DETAIL_MODAL,
    payload?: sf_dotgui_tbtp_viewmodel
}
export interface DotGuiTBTPCloseDetailModalAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_DETAIL_MODAL,
}
export interface DotGuiTBTPShowDeleteConfirmAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_DELETE_CONFIRM,
}
export interface DotGuiTBTPCloseDeleteConfirmAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_DELETE_CONFIRM,
}

export interface DotGuiTBTPSaveStartAction {
    
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_START,
    payload: sf_dotgui_tbtp
    
}
export interface DotGuiTBTPSaveSuccessAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_SUCCESS,
    payload: sf_dotgui_tbtp
}
export interface DotGuiTBTPSaveErrorAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_ERROR,
    payload: string
}
export interface DotGuiTBTPDeleteStartAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_START,
    payload: number[]
}
export interface DotGuiTBTPDeleteSuccessAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_SUCCESSS,
    payload: number[]
}
export interface DotGuiTBTPDeleteErrorAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_ERROR,
    payload: string
}

export interface DotGuiTBTPChangeSelectedIdsAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CHANGE_SELECTED_IDS,
    payload: number[]
}

export interface DotGuiTBTPChangeFilterAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CHANGE_FILTER,
    payload: NamHocTruongKhoiHeFilter
}


export interface DotGuiTBTPShowImportModal {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_IMPORT_MODEL,
}



export interface DotGuiTBTPCloseImportModal {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_IMPORT_MODEL,
}

export interface DotGuiTBTPChangeTruongAction {
    type: DotGuiTBTPActionTypeIds.DOTGUITBTP_TRUONG_CHANGED,
    payload: number
}

export type DotGuiTBTPActionTypes =
    DotGuiTBTPLoadStartAction | DotGuiTBTPLoadSuccessAction | DotGuiTBTPLoadErrorAction |
    DotGuiTBTPShowDetailModalAction | DotGuiTBTPShowDeleteConfirmAction |
    DotGuiTBTPCloseDetailModalAction | DotGuiTBTPCloseDeleteConfirmAction |
    DotGuiTBTPSaveStartAction | DotGuiTBTPSaveSuccessAction | DotGuiTBTPSaveErrorAction |
    DotGuiTBTPDeleteStartAction | DotGuiTBTPDeleteSuccessAction | DotGuiTBTPDeleteErrorAction |
    DotGuiTBTPChangeSelectedIdsAction |
    DotGuiTBTPChangeTruongAction |
    DotGuiTBTPChangeFilterAction |
    DotGuiTBTPShowImportModal | DotGuiTBTPCloseImportModal
    | DotGuiTBTPChangeTruongAction