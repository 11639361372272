import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { TongHopHocPhiActionTypeIds, TongHopHocPhiActionTypes } from "../action-types/tongHopHocPhiActionTypes";
import { TongHopHocPhiPageState } from "../page-state-model/tongHopHocPhiPageState";
const initalState: TongHopHocPhiPageState = {
    dataSource: [],
    request: {
        dm_coso_id: 0,
        dm_truong_id: 0,
        nam_hoc: localStorageHelper.getNamHoc(),
    },
    status: PageBaseStatus.is_completed
}

export const tongHopHocPhiReducer = (state: TongHopHocPhiPageState = initalState, action: TongHopHocPhiActionTypes): TongHopHocPhiPageState => {
    switch (action.type) {
        case TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                dataSource: action.payload
            }
        case TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_ERROR:
            showNotify({ message: "Không tải được dữ liệu", type: "warning" });
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_CHANGE_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        default:
            return state;
    }

}