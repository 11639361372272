import { call, put, takeEvery } from "redux-saga/effects";
import { mucPhaiThuApi } from "../../api/mucPhaiThuApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { TongHopActionTypeIds, TongHopLoadCongNoStart, TongHopLoadStart, TongHopConfirmStart, TongHopLoadHoanPhiStart,TongHopLoadHoanPhiChiTietStart } from "../action-types/tongHopActionTypes";
import { loadTongHopError, loadTongHopSuccess, loadTongHopConfirmSuccess, loadTongHopConfirmError } from "../actions/tongHocActions";

export function* tongHopSaga() {
    yield takeEvery(TongHopActionTypeIds.TONG_HOP_LOAD_START, loadTongHopStartWorker)
    yield takeEvery(TongHopActionTypeIds.TONG_HOP_CONG_NO_LOAD_START, loadTongHopCongNoStartWorker)
    yield takeEvery(TongHopActionTypeIds.TONG_HOP_HOAN_PHI_LOAD_START, loadTongHopHoanPhiStartWorker)
    yield takeEvery(TongHopActionTypeIds.TONG_HOP_HOAN_PHI_CHI_TIET_LOAD_START, loadTongHopHoanPhiChiTietStartWorker)

    yield takeEvery(TongHopActionTypeIds.TONG_HOP_CONFIRM_START, loadTongHopConfirmStartWorker)

}
function* loadTongHopStartWorker(data: TongHopLoadStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuApi.search, data.payload)
    if (res.is_success) {
        yield put(loadTongHopSuccess(res.data))
    } else {
        yield put(loadTongHopError(res.message || "Có lỗi"))
    }
}
function* loadTongHopCongNoStartWorker(data: TongHopLoadCongNoStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuApi.search_tong_hop, data.payload)
    if (res.is_success) {
        yield put(loadTongHopSuccess(res.data))
    } else {
        yield put(loadTongHopError(res.message || "Có lỗi"))
    }
}
function* loadTongHopHoanPhiStartWorker(data: TongHopLoadHoanPhiStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuApi.search_hoan_phi, data.payload)
    if (res.is_success) {
        yield put(loadTongHopSuccess(res.data))
    } else {
        yield put(loadTongHopError(res.message || "Có lỗi"))
    }
}
function* loadTongHopHoanPhiChiTietStartWorker(data: TongHopLoadHoanPhiChiTietStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuApi.search_hoan_phi_chi_tiet, data.payload)
    if (res.is_success) {
        yield put(loadTongHopSuccess(res.data))
    } else {
        yield put(loadTongHopError(res.message || "Có lỗi"))
    }
}
function* loadTongHopConfirmStartWorker(data: TongHopConfirmStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuApi.update_hinhthuc_xacnhan, data.payload)
    if (res.is_success) {
        yield put(loadTongHopConfirmSuccess(res.data))
    } else {
        yield put(loadTongHopConfirmError(res.message || "Có lỗi"))
    }
}