import jwt_decode from 'jwt-decode';
import { appInfo } from '../AppInfo';
import { IBaseResponeModel } from '../models/response/base-response';
import { axiosClient } from './axiosClient';
type jwt_decodeResult = {
    exp: any
}
let refreshTokenRequest: Promise<any> | null;

const checkIsTokenExpired = (): boolean => {
    try {
        const data: jwt_decodeResult = jwt_decode(localStorage.access_token)
        if (localStorage.access_token && data) {
            const exp = data.exp;
            if (parseInt(exp) > Math.floor(Date.now() / 1000)) {
                return false;
            }
        }
        return true;
    } catch (error) {
        return true;
    }
}

const refreshToken = () => {
    return new Promise(resolve => {
        setTimeout(async () => {
            const res: IBaseResponeModel = await axiosClient.post(`${appInfo.homeURL}/api/login/refresh_new_token`, {
                access_token: localStorage.access_token,
                refresh_token: localStorage.refresh_token
            });

            if (res.is_success) {
                resolve(res.message)
            } else {
                resolve("");
            }

        }, 1000);
    });
};
const configIfTokenExpired = async () => {
    const isTokenExpired = checkIsTokenExpired();
    if (isTokenExpired) {
        refreshTokenRequest = refreshTokenRequest
            ? refreshTokenRequest
            : refreshToken();

        const newTokens = await refreshTokenRequest;
        refreshTokenRequest = null;
        if (newTokens == "") {
            return {
                is_success: false,
                message: "Token expired."
            }
        }
        const new_access_token = newTokens.split(' ')[0];
        const new_refresh_token = newTokens.split(' ')[1];
        localStorage.access_token = new_access_token;
        localStorage.refresh_token = new_refresh_token;

    }
}
const apiClient = {
    post: async (path: string, data?: any): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            await configIfTokenExpired();
            const config = {

                headers: { Authorization: `Bearer ${localStorage.access_token}` }
            }
            const res = await axiosClient.post<any, IBaseResponeModel>(url, data, config);
            return res;
        } catch (error: any) {
            if (error.response.status == 401) {
                return {
                    status_code: parseInt(error.response.status),
                    is_success: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                }
            } else {
                return {
                    status_code: error.response.data.status_code,
                    is_success: false,
                    message: error.response.data.message || "Có lỗi"
                };
            }

        }
    },
    put: async (path: string, data?: any): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            await configIfTokenExpired();
            const config = {

                headers: { Authorization: `Bearer ${localStorage.access_token}` }
            }
            const res = await axiosClient.put<any, IBaseResponeModel>(url, data, config);
            return res;
        } catch (error: any) {
            if (error.response.status == 401) {
                return {
                    status_code: parseInt(error.response.status),
                    is_success: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                }
            } else {
                return {
                    status_code: error.response.data.status_code,
                    is_success: false,
                    message: error.response.data.message || "Có lỗi"
                };
            }

        }
    },
    delete: async (path: string): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            await configIfTokenExpired();
            const config = {
                headers: { Authorization: `Bearer ${localStorage.access_token}` }
            }
            const res = await axiosClient.delete<any, IBaseResponeModel>(url, config);
            return res;
        } catch (error: any) {
            if (error.response.status == 401) {
                return {
                    status_code: parseInt(error.response.status),
                    is_success: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                }
            } else {
                return {
                    status_code: error.response.data.status_code,
                    is_success: false,
                    message: error.response.data.message || "Có lỗi"
                };
            }

        }
    },
    get: async (path: string): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            await configIfTokenExpired();
            const config = {

                headers: { Authorization: `Bearer ${localStorage.access_token}` }
            }
            const res = await axiosClient.get<any, IBaseResponeModel>(url, config);
            return res;
        } catch (error: any) {
            if (error.response.status == 401) {
                return {
                    status_code: parseInt(error.response.status),
                    is_success: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                }
            } else {
                return {
                    status_code: error.response.data.status_code,
                    is_success: false,
                    message: error.response.data.message || "Có lỗi"
                };
            }

        }
    },
    upload: async (path: string, data?: any): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            await configIfTokenExpired();
            const config = {

                headers: {
                    Authorization: `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data',
                    language: localStorage.getItem("language"),
                }
            }
            const res = await axiosClient.post<any, IBaseResponeModel>(url, data, config);
            return res;
        } catch (error: any) {
            if (error.response.status == 401) {
                return {
                    status_code: parseInt(error.response.status),
                    is_success: false,
                    message: "Bạn không được phân quyền để thực hiện thao tác này. Vui lòng liên hệ Quản trị viên để được hỗ trợ."
                }
            } else {
                return {
                    status_code: error.response.data.status_code,
                    is_success: false,
                    message: error.response.data.message || "Có lỗi"
                };
            }

        }
    }
}
export { apiClient };
