import { NamHocTruongKhoiHeFilter } from "../models/namHocTruongKhoiHeFilter";
import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_dotgui_tbtp } from "../models/response/dot-gui/sf_dotgui_tbtp";
import { apiClient } from "./apiClient";

export const SF_DOTGUI_TBTP_SELECT_ALL_API = "sf_dotgui_tbtp/select";
export const SF_DOTGUI_TBTP_INSERT_API = "sf_dotgui_tbtp/insert";
export const SF_DOTGUI_TBTP_UPDATE_API = "sf_dotgui_tbtp/update";
export const SF_DOTGUI_TBTP_LOCK_API = "sf_dotgui_tbtp/lock";
export const SF_DOTGUI_TBTP_UNLOCK_API = "sf_dotgui_tbtp/unlock";

export const SF_DOTGUI_TBTP_DELETE_API = "sf_dotgui_tbtp/delete";
export const SF_DOTGUI_TBTP_SELECT_BY_HOSINH_API = "sf_dotgui_tbtp/select_by_hocsinh";




export const dotGuiTBTPApi = {
    selectAll: () => {
        return apiClient.get('sf_dotgui_tbtp/select-all')
    },
    select: (filter: NamHocTruongKhoiHeFilter) => {
        return apiClient.post(`${SF_DOTGUI_TBTP_SELECT_ALL_API}`, filter)
    },
    insert: (data: sf_dotgui_tbtp) => {
        return apiClient.post(`${SF_DOTGUI_TBTP_INSERT_API}`, data)
    },
    update: (data: sf_dotgui_tbtp) => {
        return apiClient.post(`${SF_DOTGUI_TBTP_UPDATE_API}`, data)
    },
    lock: (data: sf_dotgui_tbtp) => {
        return apiClient.post(`${SF_DOTGUI_TBTP_LOCK_API}`, data)
    },
    unlock: (data: sf_dotgui_tbtp) => {
        return apiClient.post(`${SF_DOTGUI_TBTP_UNLOCK_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_DOTGUI_TBTP_DELETE_API}`, model)
    },
}
