import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { DotGuiActionTypeIds, DotGuiActionTypes } from "../action-types/dotGuiActionTypes";
import { DotGuiPageState } from "../page-state-model/dotGuiPageState";
const initialState: DotGuiPageState = {
    sf_dotgui_viewmodels: [],
    sf_dotgui_selected_ids: [],
    status: PageBaseStatus.is_completed,
    filter: {
        nam_hoc: localStorageHelper.getNamHoc(),
        dm_he_id: localStorageHelper.getDmHeId(),
        dm_khoi_id: localStorageHelper.getDmKhoiId(),
        dm_truong_id: localStorageHelper.getDmTruongId()
    },
    nam_hoc: localStorageHelper.getNamHoc(),
    dm_truong_id: localStorageHelper.getDmTruongId()
}
export const dotGuiReducer = (state: DotGuiPageState = initialState, action: DotGuiActionTypes): DotGuiPageState => {
    switch (action.type) {
        case DotGuiActionTypeIds.DOTGUI_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case DotGuiActionTypeIds.DOTGUI_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_dotgui_viewmodels: action.payload
            }
        case DotGuiActionTypeIds.DOTGUI_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case DotGuiActionTypeIds.DOTGUI_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_dotgui_editing: action.payload
            }
        case DotGuiActionTypeIds.DOTGUI_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                sf_dotgui_editing: undefined
            }
        case DotGuiActionTypeIds.DOTGUI_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case DotGuiActionTypeIds.DOTGUI_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case DotGuiActionTypeIds.DOTGUI_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case DotGuiActionTypeIds.DOTGUI_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công đợt gửi ${action.payload.ten_dot_gui}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_dotgui_editing: undefined,
                is_show_detail_modal: false
            }
        case DotGuiActionTypeIds.DOTGUI_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case DotGuiActionTypeIds.DOTGUI_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case DotGuiActionTypeIds.DOTGUI_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} đợt gửi`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case DotGuiActionTypeIds.DOTGUI_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case DotGuiActionTypeIds.DOTGUI_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_dotgui_selected_ids: action.payload
            }
        case DotGuiActionTypeIds.DOTGUI_CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case DotGuiActionTypeIds.DOTGUI_SHOW_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: true
            }
        case DotGuiActionTypeIds.DOTGUI_CLOSE_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: false
            }

        case DotGuiActionTypeIds.DOTGUI_TRUONG_CHANGED:
            return {
                ...state,
                dm_truong_id: action.payload
            }
        default:
            return state;
    }
}