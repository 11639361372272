import { call, put, takeEvery } from "redux-saga/effects"
import { hinhThucThanhToanApi } from "../../api/hinhThucThanhToanApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { HinhThucThanhToanActionTypeIds, HinhThucThanhToanDeleteStartAction, HinhThucThanhToanLoadStartAction, HinhThucThanhToanSaveStartAction } from "../action-types/hinhThucThanhToanActionTypes"
import { deleteHinhThucThanhToanError, deleteHinhThucThanhToanSuccess, loadHinhThucThanhToanError, loadHinhThucThanhToanSuccess, saveHinhThucThanhToanError, saveHinhThucThanhToanSuccess } from "../actions/hinhThucThanhToanActions"
export function* hinhThucThanhToanSaga(): any {
    yield takeEvery(HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_START, loadHinhThucThanhToanStartWorker)
    yield takeEvery(HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_START, saveHinhThucThanhToanStartWorker)
    yield takeEvery(HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_START, deleteHinhThucThanhToanStartWorker)
}
function* loadHinhThucThanhToanStartWorker() {
    const res: IBaseResponeModel = yield call(hinhThucThanhToanApi.select_all)
    if (res.is_success) {
        yield put(loadHinhThucThanhToanSuccess(res.data))
    } else {
        yield put(loadHinhThucThanhToanError(res.message || ""))
    }
}
function* saveHinhThucThanhToanStartWorker(data: HinhThucThanhToanSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(hinhThucThanhToanApi.Update, data.payload)
    } else {
        res = yield call(hinhThucThanhToanApi.Insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveHinhThucThanhToanSuccess(data.payload))
        yield call(loadHinhThucThanhToanStartWorker)
    } else {
        yield put(saveHinhThucThanhToanError(res.message || ""))
    }
}
function* deleteHinhThucThanhToanStartWorker(data: HinhThucThanhToanDeleteStartAction) {
    const res: IBaseResponeModel = yield call(hinhThucThanhToanApi.Delete, data.payload)
    if (res.is_success) {
        yield put(deleteHinhThucThanhToanSuccess(data.payload))
        yield call(loadHinhThucThanhToanStartWorker)
    } else {
        yield put(deleteHinhThucThanhToanError(res.message || ""))
    }
}