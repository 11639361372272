import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { sf_dotgui, sf_dotgui_viewmodel } from "../../models/response/dot-gui/sf_dotgui"
import {
    DotGuiActionTypeIds,
    DotGuiChangeFilterAction,
    DotGuiChangeSelectedIdsAction,
    DotGuiChangeTruongAction,
    DotGuiCloseDeleteConfirmAction,
    DotGuiCloseDetailModalAction,
    DotGuiCloseImportModal,
    DotGuiDeleteErrorAction,
    DotGuiDeleteStartAction, DotGuiDeleteSuccessAction,
    DotGuiLoadErrorAction,
    DotGuiLoadStartAction, DotGuiLoadSuccessAction,
    DotGuiSaveErrorAction,
    DotGuiSaveStartAction, DotGuiSaveSuccessAction,
    DotGuiShowDeleteConfirmAction,
    DotGuiShowDetailModalAction,
    DotGuiShowImportModal
} from "../action-types/dotGuiActionTypes"

export const loadDotGuiStart = (filter: NamHocTruongKhoiHeFilter): DotGuiLoadStartAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_LOAD_START,
        payload: filter
    }
}

export const loadDotGuiSuccess = (payload: sf_dotgui_viewmodel[]): DotGuiLoadSuccessAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadDotGuiError = (payload: string): DotGuiLoadErrorAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_LOAD_ERROR,
        payload: payload
    }
}

export const showDotGuiDetailModal = (payload?: sf_dotgui_viewmodel): DotGuiShowDetailModalAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeDotGuiDetailModal = (): DotGuiCloseDetailModalAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_CLOSE_DETAIL_MODAL,
    }
}

export const showDotGuiDeleteConfirm = (): DotGuiShowDeleteConfirmAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_SHOW_DELETE_CONFIRM,
    }
}
export const closeDotGuiDeleteConfirm = (): DotGuiCloseDeleteConfirmAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_CLOSE_DELETE_CONFIRM,
    }
}

export const saveDotGuiStart = (payload: sf_dotgui): DotGuiSaveStartAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_SAVE_START,
        payload: payload
    }
}

export const saveDotGuiSuccess = (payload: sf_dotgui): DotGuiSaveSuccessAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveDotGuiError = (payload: string): DotGuiSaveErrorAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_SAVE_ERROR,
        payload: payload
    }
}

export const deleteDotGuiStart = (payload: number[]): DotGuiDeleteStartAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_DELETE_START,
        payload: payload
    }
}

export const deleteDotGuiSuccess = (payload: number[]): DotGuiDeleteSuccessAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteDotGuiError = (payload: string): DotGuiDeleteErrorAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_DELETE_ERROR,
        payload: payload
    }
}

export const changeDotGuiSelectionIds = (payload: number[]): DotGuiChangeSelectedIdsAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_CHANGE_SELECTED_IDS,
        payload: payload
    }
}

export const changeDotGuiFilter = (filter: NamHocTruongKhoiHeFilter): DotGuiChangeFilterAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_CHANGE_FILTER,
        payload: filter
    }
}

export const showDotGuiImportModal = (): DotGuiShowImportModal => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_SHOW_IMPORT_MODEL
    }
}

export const closeDotGuiImportModal = (): DotGuiCloseImportModal => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_CLOSE_IMPORT_MODEL
    }
}

export const changeTruong = (payload: number): DotGuiChangeTruongAction => {
    return {
        type: DotGuiActionTypeIds.DOTGUI_TRUONG_CHANGED,
        payload: payload
    }
}