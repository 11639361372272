import { NamHocTruongKhoiHeFilter } from "../models/namHocTruongKhoiHeFilter";
import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_dotgui, sf_dotgui_quyettoan } from "../models/response/dot-gui/sf_dotgui";
import { apiClient } from "./apiClient";

export const SF_DOTGUI_SELECT_ALL_API = "sf_dotgui/select";
export const SF_DOTGUI_INSERT_API = "sf_dotgui/insert";
export const SF_DOTGUI_UPDATE_API = "sf_dotgui/update";
export const SF_DOTGUI_DELETE_API = "sf_dotgui/delete";
export const SF_DOTGUI_SELECT_BY_HOSINH_API = "sf_dotgui/select_by_hocsinh";

export const SF_DOTGUI_QUYETTOAN_INSERT_API = "sf_dotgui_quyettoan/insert";
export const SF_DOTGUI_QUYETTOAN__SELECT_ALL_API = "sf_dotgui_quyettoan/select";



export const dotGuiApi = {
    select: (filter: NamHocTruongKhoiHeFilter) => {
        return apiClient.post(`${SF_DOTGUI_SELECT_ALL_API}`, filter)
    },
    insert: (data: sf_dotgui) => {
        return apiClient.post(`${SF_DOTGUI_INSERT_API}`, data)
    },
    update: (data: sf_dotgui) => {
        return apiClient.post(`${SF_DOTGUI_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_DOTGUI_DELETE_API}`, model)
    },
    select_dotgui_quyettoan: (filter: NamHocTruongKhoiHeFilter) => {
        return apiClient.post(`${SF_DOTGUI_QUYETTOAN__SELECT_ALL_API}`, filter)
    },
    insert_dotgui_quyettoan: (data: sf_dotgui_quyettoan) => {
        return apiClient.post(`${SF_DOTGUI_QUYETTOAN_INSERT_API}`, data)
    },
}
