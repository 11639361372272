import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { sf_dotgui, sf_dotgui_viewmodel } from "../../models/response/dot-gui/sf_dotgui"
export enum DotGuiActionTypeIds {
    DOTGUI_LOAD_START = "DOTGUI_LOAD_START",
    DOTGUI_LOAD_SUCCESS = "DOTGUI_LOAD_SUCCESS",
    DOTGUI_LOAD_ERROR = "DOTGUI_LOAD_ERROR",

    DOTGUI_SHOW_DETAIL_MODAL = "DOTGUI_SHOW_DETAIL_MODAL",
    DOTGUI_CLOSE_DETAIL_MODAL = "DOTGUI_CLOSE_DETAIL_MODAL",

    DOTGUI_SHOW_DELETE_CONFIRM = "DOTGUI_SHOW_DELETE_CONFIRM",
    DOTGUI_CLOSE_DELETE_CONFIRM = "DOTGUI_CLOSE_DELETE_CONFIRM",

    DOTGUI_SAVE_START = "DOTGUI_SAVE_START",
    DOTGUI_SAVE_SUCCESS = "DOTGUI_SAVE_SUCCESS",
    DOTGUI_SAVE_ERROR = "DOTGUI_SAVE_ERROR",

    DOTGUI_DELETE_START = "DOTGUI_DELETE_START",
    DOTGUI_DELETE_SUCCESSS = "DOTGUI_DELETE_SUCCESSS",
    DOTGUI_DELETE_ERROR = "DOTGUI_DELETE_ERROR",

    DOTGUI_CHANGE_SELECTED_IDS = "DOTGUI_CHANGE_SELECTED_IDS",

    DOTGUI_CHANGE_FILTER = "DOTGUI_CHANGE_FILTER",

    DOTGUI_SHOW_IMPORT_MODEL = "DOTGUI_SHOW_IMPORT_MODEL",
    DOTGUI_CLOSE_IMPORT_MODEL = "DOTGUI_CLOSE_IMPORT_MODEL",
    
    DOTGUI_TRUONG_CHANGED = "DOTGUI_TRUONG_CHANGED"

}

export interface DotGuiLoadStartAction {
    type: DotGuiActionTypeIds.DOTGUI_LOAD_START,
    payload: NamHocTruongKhoiHeFilter
}
export interface DotGuiLoadSuccessAction {
    type: DotGuiActionTypeIds.DOTGUI_LOAD_SUCCESS,
    payload: sf_dotgui_viewmodel[]
}
export interface DotGuiLoadErrorAction {
    type: DotGuiActionTypeIds.DOTGUI_LOAD_ERROR,
    payload: string
}

export interface DotGuiShowDetailModalAction {
    type: DotGuiActionTypeIds.DOTGUI_SHOW_DETAIL_MODAL,
    payload?: sf_dotgui_viewmodel
}
export interface DotGuiCloseDetailModalAction {
    type: DotGuiActionTypeIds.DOTGUI_CLOSE_DETAIL_MODAL,
}
export interface DotGuiShowDeleteConfirmAction {
    type: DotGuiActionTypeIds.DOTGUI_SHOW_DELETE_CONFIRM,
}
export interface DotGuiCloseDeleteConfirmAction {
    type: DotGuiActionTypeIds.DOTGUI_CLOSE_DELETE_CONFIRM,
}

export interface DotGuiSaveStartAction {
    
    type: DotGuiActionTypeIds.DOTGUI_SAVE_START,
    payload: sf_dotgui
    
}
export interface DotGuiSaveSuccessAction {
    type: DotGuiActionTypeIds.DOTGUI_SAVE_SUCCESS,
    payload: sf_dotgui
}
export interface DotGuiSaveErrorAction {
    type: DotGuiActionTypeIds.DOTGUI_SAVE_ERROR,
    payload: string
}
export interface DotGuiDeleteStartAction {
    type: DotGuiActionTypeIds.DOTGUI_DELETE_START,
    payload: number[]
}
export interface DotGuiDeleteSuccessAction {
    type: DotGuiActionTypeIds.DOTGUI_DELETE_SUCCESSS,
    payload: number[]
}
export interface DotGuiDeleteErrorAction {
    type: DotGuiActionTypeIds.DOTGUI_DELETE_ERROR,
    payload: string
}

export interface DotGuiChangeSelectedIdsAction {
    type: DotGuiActionTypeIds.DOTGUI_CHANGE_SELECTED_IDS,
    payload: number[]
}

export interface DotGuiChangeFilterAction {
    type: DotGuiActionTypeIds.DOTGUI_CHANGE_FILTER,
    payload: NamHocTruongKhoiHeFilter
}


export interface DotGuiShowImportModal {
    type: DotGuiActionTypeIds.DOTGUI_SHOW_IMPORT_MODEL,
}



export interface DotGuiCloseImportModal {
    type: DotGuiActionTypeIds.DOTGUI_CLOSE_IMPORT_MODEL,
}

export interface DotGuiChangeTruongAction {
    type: DotGuiActionTypeIds.DOTGUI_TRUONG_CHANGED,
    payload: number
}

export type DotGuiActionTypes =
    DotGuiLoadStartAction | DotGuiLoadSuccessAction | DotGuiLoadErrorAction |
    DotGuiShowDetailModalAction | DotGuiShowDeleteConfirmAction |
    DotGuiCloseDetailModalAction | DotGuiCloseDeleteConfirmAction |
    DotGuiSaveStartAction | DotGuiSaveSuccessAction | DotGuiSaveErrorAction |
    DotGuiDeleteStartAction | DotGuiDeleteSuccessAction | DotGuiDeleteErrorAction |
    DotGuiChangeSelectedIdsAction |
    DotGuiChangeFilterAction |
    DotGuiShowImportModal | DotGuiCloseImportModal
    | DotGuiChangeTruongAction