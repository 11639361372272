import { call, put, takeEvery } from "redux-saga/effects";
import { mucPhaiThuChiTietApi } from "../../api/mucPhaiThuChiTietApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { TongHopHocPhiActionTypeIds, TongHopHocPhiLoadStart } from "../action-types/tongHopHocPhiActionTypes";
import { loadTongHopHocPhiError, loadTongHopHocPhiSuccess } from "../actions/tongHopHocPhiActions";

export function* tongHopHocPhiSaga() {
    yield takeEvery(TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_START, loadTongHopHocPhiStartWorker)
}
function* loadTongHopHocPhiStartWorker(data: TongHopHocPhiLoadStart) {
    const res: IBaseResponeModel = yield call(mucPhaiThuChiTietApi.chiTietHocPhi, data.payload)
    if (res.is_success) {
        yield put(loadTongHopHocPhiSuccess(res.data))
    } else {
        yield put(loadTongHopHocPhiError(res.message || "Có lỗi"))
    }
}