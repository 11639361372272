
import { apiClient } from "./apiClient";

export const SF_PHANBOPHI_SELECT_BY_SCT = "sf_phanbophi/select_by_sct"
export const SF_PHANBOPHI_SAVECHANGES = "sf_phanbophi/savechanges"
export const SF_PHANBOPHI_SAVEFINISH = "sf_phanbophi/finish"

export const phanBoPhiApi = {
    select_by_sct: (sf_mucphaithu_id: number) => apiClient.post(`${SF_PHANBOPHI_SELECT_BY_SCT}?sf_mucphaithu_id=${sf_mucphaithu_id}`),
    save_changes: (data: any) => apiClient.post(`${SF_PHANBOPHI_SAVECHANGES}`, data),
    save_finish: (sf_mucphaithu_id: number, ngaythu: Date, nam_hoc_dong_phi: string) => apiClient.post(`${SF_PHANBOPHI_SAVEFINISH}?sf_mucphaithu_id=${sf_mucphaithu_id}&ngaythu=${ngaythu}&nam_hoc_dong_phi=${nam_hoc_dong_phi}`),
}
