import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { KhoanNopGroupActionTypeIds, KhoanNopGroupActionTypes } from "../action-types/khoanNopGroupActionTypes";
import { KhoanNopGroupPageState } from "../page-state-model/khoanNopGroupPageState";
const initialState: KhoanNopGroupPageState = {
    sf_khoannop_groups: [],
    sf_khoannop_group_selected_ids: [],
    status: PageBaseStatus.is_not_initial
}
export const khoanNopGroupReducer = (state: KhoanNopGroupPageState = initialState, action: KhoanNopGroupActionTypes): KhoanNopGroupPageState => {
    switch (action.type) {
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_khoannop_groups: action.payload
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case KhoanNopGroupActionTypeIds.KHOANOPGROUP_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_khoannop_group_editing: action.payload
            }
        case KhoanNopGroupActionTypeIds.KHOANOPGROUP_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: action.payload
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công nhóm khoản nộp ${action.payload.ma_khoan_nop_group}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_khoannop_group_editing: undefined,
                is_show_detail_modal: false
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case KhoanNopGroupActionTypeIds.KHOANNOPGROUP_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_khoannop_group_selected_ids: action.payload
            }
        default:
            return state;
    }
}