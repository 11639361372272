import { PageBaseStatus } from "../../models/pageBaseStatus"
import { sf_dayoff_delete_request } from "../../models/request/dayoff/sf_dayoff_delete_request"
import { sf_dayoff_model } from "../../models/request/dayoff/sf_dayoff_model"
import { dayoff_detail_view_model } from "../../models/response/dayoff/dayoff_detail_view_model"
import { sf_dayoff } from "../../models/response/dayoff/sf_dayoff"
import {
    dayoffActionTypeIds, DayoffChangeSelectedIdsAction, DayoffCloseDeleteConfirmAction, DayoffCloseDetailModalAction, DayoffDeleteErrorAction, DayoffDeleteStartAction, DayoffDeleteSuccessAction, DayoffLoadErrorAction, DayoffLoadStartAction, DayoffLoadSuccessAction, DayoffSaveErrorAction, DayoffSaveStartAction, DayoffSaveSuccessAction, DayoffShowDeleteConfirmAction, DayoffShowDetailModalAction
} from "../action-types/dayoffActionTypes"

export const loadDayoffStart = (payload: number): DayoffLoadStartAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_LOAD_START,
        payload: payload
    }
}

export const loadDayoffSuccess = (payload: dayoff_detail_view_model[]): DayoffLoadSuccessAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadDayoffError = (payload: string): DayoffLoadErrorAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_LOAD_ERROR,
        payload: payload
    }
}

export const showDayoffDetailModal = (payload?: dayoff_detail_view_model): DayoffShowDetailModalAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeDayoffDetailModal = (): DayoffCloseDetailModalAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_CLOSE_DETAIL_MODAL,
    }
}

export const showDayoffDeleteConfirm = (): DayoffShowDeleteConfirmAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_SHOW_DELETE_CONFIRM,
    }
}
export const closeDayoffDeleteConfirm = (): DayoffCloseDeleteConfirmAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_CLOSE_DELETE_CONFIRM,
    }
}

export const saveDayoffStart = (payload: dayoff_detail_view_model): DayoffSaveStartAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_SAVE_START,
        payload: payload
    }
}

export const saveDayoffSuccess = (payload: dayoff_detail_view_model): DayoffSaveSuccessAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveDayoffError = (payload: string): DayoffSaveErrorAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_SAVE_ERROR,
        payload: payload
    }
}

export const deleteDayoffStart = (payload: sf_dayoff_delete_request): DayoffDeleteStartAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_DELETE_START,
        payload: payload
    }
}

export const deleteDayoffSuccess = (payload: sf_dayoff_delete_request): DayoffDeleteSuccessAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteDayoffError = (payload: string): DayoffDeleteErrorAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_DELETE_ERROR,
        payload: payload
    }
}

export const changeDayoffSelectionIds = (payload: number[]): DayoffChangeSelectedIdsAction => {
    return {
        type: dayoffActionTypeIds.DAYOFF_CHANGE_SELECTED_IDS,
        payload: payload
    }
}