import { call, put, takeEvery } from "redux-saga/effects"
import { hachToanCongNoApi } from "../../api/hachToanCongNopApi"
import { mucThuPhiApi } from "../../api/mucThuPhiApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { HachToanCongNoActionTypeIds, HachToanCongNoDeleteStartAction, HachToanCongNoLoadStartAction, HachToanCongNoRefundStartAction, HachToanCongNoSaveStartAction } from "../action-types/hachToanCongNoActionTypes"
import {
    deleteHachToanCongNoError, deleteHachToanCongNoSuccess, loadHachToanCongNoError, loadHachToanCongNoSuccess,
    refundHachToanCongNoError,
    refundHachToanCongNoSuccess,
    saveHachToanCongNoError, saveHachToanCongNoSuccess
} from "../actions/hachToanCongNoActions"
export function* hachToanCongNoSagas(): any {
    yield takeEvery(HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_START, loadHachToanCongNoWorker)
    yield takeEvery(HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_START, saveHachToanCongNoWorker)
    yield takeEvery(HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_START, deleteHachToanCongNoWorker)
    yield takeEvery(HachToanCongNoActionTypeIds.HACHTOANCONGNO_REFUND_START, refundHachToanCongNoWorker)

}
function* loadHachToanCongNoWorker(data: HachToanCongNoLoadStartAction) {
    const res: IBaseResponeModel = yield call(hachToanCongNoApi.select, data.payload)
    if (res.is_success) {
         yield put(loadHachToanCongNoSuccess(res.data))
    } else {
        yield put(loadHachToanCongNoError(res.message || ""))
    }
}
function* saveHachToanCongNoWorker(data: HachToanCongNoSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(hachToanCongNoApi.update, data.payload)
    } else {
        res = yield call(hachToanCongNoApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveHachToanCongNoSuccess(data.payload))
    } else {
        yield put(saveHachToanCongNoError(res.message || ""))
    }
}
function* deleteHachToanCongNoWorker(data: HachToanCongNoDeleteStartAction) {
    const res: IBaseResponeModel = yield call(hachToanCongNoApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteHachToanCongNoSuccess(data.payload))
    } else {
        yield put(deleteHachToanCongNoError(res.message || ""))
    }
}
function* refundHachToanCongNoWorker(data: HachToanCongNoRefundStartAction) {
    const res: IBaseResponeModel = yield call(hachToanCongNoApi.refund, data.payload)
    if (res.is_success) {
        yield put(refundHachToanCongNoSuccess(data.payload))
    } else {
        yield put(refundHachToanCongNoError(res.message || ""))
    }
}