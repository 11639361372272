import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { TongHopActionTypeIds, TongHopActionTypes } from "../action-types/tongHopActionTypes";
import { TongHopPageState } from "../page-state-model/tongHopPageState";
const initalState: TongHopPageState = {
    dataSource: [],
    mucPhaiThus: [],
    phanBoPhis: [],
    request: {
        nam_hoc_ids:[],
        dm_coso_id: 0,
        username: "",
        den_ngay_ts: Math.floor(new Date().getTime() / 1000),
        dm_truong_id: 0,
        ma_hs: "",
        nam_hoc: localStorageHelper.getNamHoc(),
        tu_ngay_ts: Math.floor(new Date().getTime() / 1000),
        is_show_ct_huy: false,
        sf_hinhthucthanhtoan_id: 0,
        sf_hinhthucxacnhan_id: 0,
        is_search_simple:false
    },
    confirm_request: {
        sf_mucphaithu_ids: [],
        sf_hinhthucxacnhan_id: 0
    },
    status: PageBaseStatus.is_completed,
    chungTuSelectedIds: []
}

export const tongHopReducer = (state: TongHopPageState = initalState, action: TongHopActionTypes): TongHopPageState => {
    switch (action.type) {
        case TongHopActionTypeIds.TONG_HOP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopActionTypeIds.TONG_HOP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                dataSource: action.payload,
                mucPhaiThus: action.payload.mucPhaiThus,
                phanBoPhis: action.payload.phanBoPhis
            }
        case TongHopActionTypeIds.TONG_HOP_LOAD_ERROR:
            showNotify({ message: "Không tải được dữ liệu", type: "warning" });
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TongHopActionTypeIds.TONG_HOP_CHANGE_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case TongHopActionTypeIds.TONG_HOP_CONG_NO_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopActionTypeIds.TONG_HOP_HOAN_PHI_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopActionTypeIds.TONG_HOP_HOAN_PHI_CHI_TIET_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopActionTypeIds.CHANGE_CHUNGTU_SELECTED_IDS:
            return {
                ...state,
                chungTuSelectedIds: action.payload
            }
        case TongHopActionTypeIds.TONG_HOP_CONFIRM_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case TongHopActionTypeIds.TONG_HOP_CONFIRM_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TongHopActionTypeIds.TONG_HOP_CONFIRM_ERROR:
            showNotify({ message: "Có lỗi xảy ra", type: "warning" });
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case TongHopActionTypeIds.TONG_HOP_CHANGE_CONFIRM_REQUEST:
            return {
                ...state,
                confirm_request: action.payload
            }
        default:
            return state;
    }

}