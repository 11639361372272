import { sf_mucphaithu_search_rq } from "../../models/request/tonghop/sf_mucphaithu_search_rq"
import { sf_mucphaithu_confirm_rq } from "../../models/request/tonghop/sf_mucphaithu_confirm_rq"
import {
    TongHopActionTypeIds, TongHopChangeChungTuSelectedIds, TongHopChangeRequest,
    TongHopLoadCongNoStart,
    TongHopLoadError,
    TongHopLoadStart, TongHopLoadSucess, TongHopConfirmStart,TongHopConfirmSucess,TongHopConfirmError, TongHopChangeConfirmRequest, TongHopLoadHoanPhiStart,TongHopLoadHoanPhiChiTietStart
} from "../action-types/tongHopActionTypes"
import { sf_mucphaithu_search_rq1 } from "../../models/request/tonghop/sf_mucphaithu_search_rq1"

export const loadTongHopStart = (rq: sf_mucphaithu_search_rq): TongHopLoadStart => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_LOAD_START,
        payload: rq
    }
}
export const loadTongHopCongNoStart = (rq: sf_mucphaithu_search_rq): TongHopLoadCongNoStart => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CONG_NO_LOAD_START,
        payload: rq
    }
}
export const loadTongHopHoanPhiStart = (rq: sf_mucphaithu_search_rq): TongHopLoadHoanPhiStart => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_HOAN_PHI_LOAD_START,
        payload: rq
    }
}
export const loadTongHopHoanPhiChiTietStart = (rq: sf_mucphaithu_search_rq): TongHopLoadHoanPhiChiTietStart => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_HOAN_PHI_CHI_TIET_LOAD_START,
        payload: rq
    }
}
export const loadTongHopSuccess = (payload: any[]): TongHopLoadSucess => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_LOAD_SUCCESS,
        payload: payload
    }
}

export const loadTongHopError= (payload: string): TongHopLoadError => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_LOAD_ERROR,
        payload: payload
    }
}


export const changeTongHopRequest= (payload: sf_mucphaithu_search_rq): TongHopChangeRequest => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CHANGE_REQUEST,
        payload: payload
    }
}
export const changeTongHopRequest1= (payload: sf_mucphaithu_search_rq1): TongHopChangeRequest => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CHANGE_REQUEST,
        payload: payload
    }
}
export const loadTongHopConfirmStart = (rq: sf_mucphaithu_confirm_rq): TongHopConfirmStart => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CONFIRM_START,
        payload: rq
    }
}
export const loadTongHopConfirmSuccess = (payload: any[]): TongHopConfirmSucess => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CONFIRM_SUCCESS,
        payload: payload
    }
}

export const loadTongHopConfirmError= (payload: string): TongHopConfirmError => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CONFIRM_ERROR,
        payload: payload
    }
}
export const changeTongHopConfirmRequest= (payload: sf_mucphaithu_confirm_rq): TongHopChangeConfirmRequest => {
    return {
        type: TongHopActionTypeIds.TONG_HOP_CHANGE_CONFIRM_REQUEST,
        payload: payload
    }
}
export const changeChungTuSelectedIds= (payload: number[]): TongHopChangeChungTuSelectedIds => {
    return {
        type: TongHopActionTypeIds.CHANGE_CHUNGTU_SELECTED_IDS,
        payload: payload
    }
}