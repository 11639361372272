import { call, put, takeEvery } from "redux-saga/effects"
import { dotGuiTBTPApi } from "../../api/dotGuiTBTPApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { DotGuiTBTPActionTypeIds, DotGuiTBTPDeleteStartAction, DotGuiTBTPLoadStartAction, DotGuiTBTPSaveStartAction } from "../action-types/dotGuiTBTPActionTypes"
import {
    deleteDotGuiError, deleteDotGuiSuccess, loadDotGuiError, loadDotGuiSuccess,
    saveDotGuiError, saveDotGuiSuccess
} from "../actions/dotGuiTBTPAction"
export function* dotGuiTBTPSagas(): any {
    yield takeEvery(DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_START, loadDotGuiWorker)
    yield takeEvery(DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_START, saveDotGuiWorker)
    yield takeEvery(DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_START, deleteDotGuiWorker)
}
function* loadDotGuiWorker(data: DotGuiTBTPLoadStartAction) {
    const res: IBaseResponeModel = yield call(dotGuiTBTPApi.select, data.payload)
    if (res.is_success) {
         yield put(loadDotGuiSuccess(res.data))
    } else {
        yield put(loadDotGuiError(res.message || ""))
    }
}
function* saveDotGuiWorker(data: DotGuiTBTPSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(dotGuiTBTPApi.update, data.payload)
    } else {
        res = yield call(dotGuiTBTPApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveDotGuiSuccess(data.payload))
    } else {
        yield put(saveDotGuiError(res.message || ""))
    }
}
function* deleteDotGuiWorker(data: DotGuiTBTPDeleteStartAction) {
    const res: IBaseResponeModel = yield call(dotGuiTBTPApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteDotGuiSuccess(data.payload))
    } else {
        yield put(deleteDotGuiError(res.message || ""))
    }
}