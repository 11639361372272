import { sf_mucphaithu_search_rq } from "../../models/request/tonghop/sf_mucphaithu_search_rq"
import { sf_mucphaithu_confirm_rq } from "../../models/request/tonghop/sf_mucphaithu_confirm_rq"

export enum TongHopActionTypeIds {
    TONG_HOP_LOAD_START = "TONG_HOP_LOAD_START",
    TONG_HOP_LOAD_SUCCESS = "TONG_HOP_LOAD_SUCCESS",
    TONG_HOP_LOAD_ERROR = "TONG_HOP_LOAD_ERROR",
    TONG_HOP_CHANGE_REQUEST = "TONG_HOP_CHANGE_REQUEST",
    TONG_HOP_CONG_NO_LOAD_START = "TONG_HOP_CONG_NO_LOAD_START",
    TONG_HOP_HOAN_PHI_LOAD_START = "TONG_HOP_HOAN_PHI_LOAD_START",
    TONG_HOP_HOAN_PHI_CHI_TIET_LOAD_START = "TONG_HOP_HOAN_PHI_CHI_TIET_LOAD_START",

    TONG_HOP_CONFIRM_START = "TONG_HOP_CONFIRM_START",
    TONG_HOP_CONFIRM_SUCCESS = "TONG_HOP_CONFIRM_SUCCESS",
    TONG_HOP_CONFIRM_ERROR = "TONG_HOP_CONFIRM_ERROR",
    TONG_HOP_CHANGE_CONFIRM_REQUEST ="TONG_HOP_CHANGE_CONFIRM_REQUEST",
    CHANGE_CHUNGTU_SELECTED_IDS = "CHANGE_CHUNGTU_SELECTED_IDS",

}

export interface TongHopLoadStart {
    type: TongHopActionTypeIds.TONG_HOP_LOAD_START,
    payload: sf_mucphaithu_search_rq
}
export interface TongHopLoadCongNoStart {
    type: TongHopActionTypeIds.TONG_HOP_CONG_NO_LOAD_START,
    payload: sf_mucphaithu_search_rq
}
export interface TongHopLoadHoanPhiStart {
    type: TongHopActionTypeIds.TONG_HOP_HOAN_PHI_LOAD_START,
    payload: sf_mucphaithu_search_rq
}
export interface TongHopLoadHoanPhiChiTietStart {
    type: TongHopActionTypeIds.TONG_HOP_HOAN_PHI_CHI_TIET_LOAD_START,
    payload: sf_mucphaithu_search_rq
}
export interface TongHopLoadSucess {
    type: TongHopActionTypeIds.TONG_HOP_LOAD_SUCCESS,
    payload: any
}
export interface TongHopLoadError {
    type: TongHopActionTypeIds.TONG_HOP_LOAD_ERROR,
    payload: string
}
export interface TongHopChangeRequest {
    type: TongHopActionTypeIds.TONG_HOP_CHANGE_REQUEST,
    payload: sf_mucphaithu_search_rq

}
export interface TongHopChangeChungTuSelectedIds {
    type: TongHopActionTypeIds.CHANGE_CHUNGTU_SELECTED_IDS,
    payload: number[]

}
export interface TongHopConfirmStart {
    type: TongHopActionTypeIds.TONG_HOP_CONFIRM_START,
    payload: sf_mucphaithu_confirm_rq
}
export interface TongHopConfirmSucess {
    type: TongHopActionTypeIds.TONG_HOP_CONFIRM_SUCCESS,
    payload: any
}
export interface TongHopConfirmError {
    type: TongHopActionTypeIds.TONG_HOP_CONFIRM_ERROR,
    payload: string
}
export interface TongHopChangeConfirmRequest {
    type: TongHopActionTypeIds.TONG_HOP_CHANGE_CONFIRM_REQUEST,
    payload: sf_mucphaithu_confirm_rq

}
export type TongHopActionTypes = TongHopLoadStart | TongHopLoadSucess | TongHopLoadError | TongHopChangeRequest |TongHopLoadCongNoStart|TongHopChangeChungTuSelectedIds
|TongHopConfirmStart|TongHopConfirmSucess|TongHopConfirmError|TongHopChangeConfirmRequest |TongHopLoadHoanPhiStart|TongHopLoadHoanPhiChiTietStart