import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { KhoanNopActionTypeIds, KhoanNopActionTypes } from "../action-types/khoanNopActionTypes";
import { KhoanNopPageState } from "../page-state-model/khoanNopPageState";
const initialState: KhoanNopPageState = {
    sf_khoannops: [],
    sf_khoannop_selected_ids: [],
    status: PageBaseStatus.is_not_initial
}
export const khoanNopReducer = (state: KhoanNopPageState = initialState, action: KhoanNopActionTypes): KhoanNopPageState => {
    switch (action.type) {
        case KhoanNopActionTypeIds.KHOANNOP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case KhoanNopActionTypeIds.KHOANNOP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_khoannops: action.payload
            }
        case KhoanNopActionTypeIds.KHOANNOP_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case KhoanNopActionTypeIds.KHOANNOP_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_khoannop_editing: action.payload
            }
        case KhoanNopActionTypeIds.KHOANNOP_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                sf_khoannop_editing: undefined
            }
        case KhoanNopActionTypeIds.KHOANNOP_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case KhoanNopActionTypeIds.KHOANNOP_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case KhoanNopActionTypeIds.KHOANNOP_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case KhoanNopActionTypeIds.KHOANNOP_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công khoản nộp ${action.payload.ma_khoan_nop}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_khoannop_editing: undefined,
                is_show_detail_modal: false
            }
        case KhoanNopActionTypeIds.KHOANNOP_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case KhoanNopActionTypeIds.KHOANNOP_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case KhoanNopActionTypeIds.KHOANNOP_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case KhoanNopActionTypeIds.KHOANNOP_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case KhoanNopActionTypeIds.KHOANNOP_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_khoannop_selected_ids: action.payload
            }
        default:
            return state;
    }
}