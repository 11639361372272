import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { HinhThucMienGiamActionTypeIds, HinhThucMienGiamActionTypes } from "../action-types/hinhThucMienGiamActionTypes";
import { HinhThucThanhToanActionTypeIds, HinhThucThanhToanActionTypes } from "../action-types/hinhThucThanhToanActionTypes";
import { HinhThucMienGiamPageState } from "../page-state-model/hinhThucMienGiamPageState";
import { HinhThucThanhToanPageState } from "../page-state-model/hinhThucThanhToanPageState";
const initialState: HinhThucThanhToanPageState = {
    sf_hinhthucthanhtoan: [],
    sf_hinhthucthanhtoan_selected_ids: [],
    status: PageBaseStatus.is_completed,
    sf_hinhthucthanhtoan_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}
export const hinhThucThanhToanReducer = (state: HinhThucThanhToanPageState = initialState, action: HinhThucThanhToanActionTypes): HinhThucThanhToanPageState => {
    switch (action.type) {
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_hinhthucthanhtoan: action.payload
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_hinhthucthanhtoan_editing: action.payload
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                // sf_hinhthucmiengiam_editing: undefined
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công hình thức thanh toán ${action.payload.hinh_thuc_thanh_toan}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_hinhthucthanhtoan_editing: undefined,
                is_show_detail_modal: false
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} hình thức thanh toán`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case HinhThucThanhToanActionTypeIds.HINHTHUCTHANHTOAN_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_hinhthucthanhtoan_selected_ids: action.payload
            }        
        default:
            return state;
    }
}