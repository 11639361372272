import { ts_hocsinh_viewmodel } from './../../models/response/hocsinh/ts_hocsinh';
import { sf_saleset_register_dichvu } from './../../models/response/sale-set-dichvu/sf_saleset_register_dichvu';
import { NamHocTruongKhoiHeFilter } from './../../models/namHocTruongKhoiHeFilter';
import { eSaleSetDichVuTypeIds } from './../action-types/saleSetRegisterDichVuActionTypes';
import { baseAction } from './BaseActions';
export const saleSetDichVuAction = {
    loadStart: (filter: NamHocTruongKhoiHeFilter) => ({
        type: eSaleSetDichVuTypeIds.LOAD_START,
        payload: filter
    }),
    loadSuccess: (data: sf_saleset_register_dichvu[]) => ({
        type: eSaleSetDichVuTypeIds.LOAD_SUCCESS,
        payload: data
    }),
    loadError: (message: string) => ({
        type: eSaleSetDichVuTypeIds.LOAD_ERRROR,
        payload: message
    }),
    loadHocSinhStart: (filter: NamHocTruongKhoiHeFilter) => baseAction(eSaleSetDichVuTypeIds.LOAD_HOCSINH_START, filter),
    loadHocSinhSuccess: (data: ts_hocsinh_viewmodel[]) => baseAction(eSaleSetDichVuTypeIds.LOAD_HOCSINH_SUCCESS, data),
    loadHocSinhErr: (message: string) => baseAction(eSaleSetDichVuTypeIds.LOAD_HOCSINH_ERROR, message),

    changeFilter: (filter: NamHocTruongKhoiHeFilter) => baseAction(eSaleSetDichVuTypeIds.CHANGE_FILTER, filter)
}

export const showRegisterDichVuImportModal = () => ({ type: eSaleSetDichVuTypeIds.SHOW_IMPORT_MODAL })
export const closeRegisterDichVuImportModal = () => ({ type: eSaleSetDichVuTypeIds.CLOSE_IMPORT_MODAL })