import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { sf_dotgui_tbtp, sf_dotgui_tbtp_viewmodel } from "../../models/response/dot-gui/sf_dotgui_tbtp"
import {
    DotGuiTBTPActionTypeIds,
    DotGuiTBTPChangeFilterAction,
    DotGuiTBTPChangeSelectedIdsAction,
    DotGuiTBTPChangeTruongAction,
    DotGuiTBTPCloseDeleteConfirmAction,
    DotGuiTBTPCloseDetailModalAction,
    DotGuiTBTPCloseImportModal,
    DotGuiTBTPDeleteErrorAction,
    DotGuiTBTPDeleteStartAction, DotGuiTBTPDeleteSuccessAction,
    DotGuiTBTPLoadErrorAction,
    DotGuiTBTPLoadStartAction, DotGuiTBTPLoadSuccessAction,
    DotGuiTBTPSaveErrorAction,
    DotGuiTBTPSaveStartAction, DotGuiTBTPSaveSuccessAction,
    DotGuiTBTPShowDeleteConfirmAction,
    DotGuiTBTPShowDetailModalAction,
    DotGuiTBTPShowImportModal
} from "../action-types/dotGuiTBTPActionTypes"

export const loadDotGuiStart = (filter: NamHocTruongKhoiHeFilter): DotGuiTBTPLoadStartAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_START,
        payload: filter
    }
}

export const loadDotGuiSuccess = (payload: sf_dotgui_tbtp_viewmodel[]): DotGuiTBTPLoadSuccessAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadDotGuiError = (payload: string): DotGuiTBTPLoadErrorAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_ERROR,
        payload: payload
    }
}

export const showDotGuiDetailModal = (payload?: sf_dotgui_tbtp_viewmodel): DotGuiTBTPShowDetailModalAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeDotGuiDetailModal = (): DotGuiTBTPCloseDetailModalAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_DETAIL_MODAL,
    }
}

export const showDotGuiDeleteConfirm = (): DotGuiTBTPShowDeleteConfirmAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_DELETE_CONFIRM,
    }
}
export const closeDotGuiDeleteConfirm = (): DotGuiTBTPCloseDeleteConfirmAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_DELETE_CONFIRM,
    }
}

export const saveDotGuiStart = (payload: sf_dotgui_tbtp): DotGuiTBTPSaveStartAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_START,
        payload: payload
    }
}

export const saveDotGuiSuccess = (payload: sf_dotgui_tbtp): DotGuiTBTPSaveSuccessAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveDotGuiError = (payload: string): DotGuiTBTPSaveErrorAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_ERROR,
        payload: payload
    }
}

export const deleteDotGuiStart = (payload: number[]): DotGuiTBTPDeleteStartAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_START,
        payload: payload
    }
}

export const deleteDotGuiSuccess = (payload: number[]): DotGuiTBTPDeleteSuccessAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteDotGuiError = (payload: string): DotGuiTBTPDeleteErrorAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_ERROR,
        payload: payload
    }
}

export const changeDotGuiSelectionIds = (payload: number[]): DotGuiTBTPChangeSelectedIdsAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CHANGE_SELECTED_IDS,
        payload: payload
    }
}

export const changeDotGuiFilter = (filter: NamHocTruongKhoiHeFilter): DotGuiTBTPChangeFilterAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CHANGE_FILTER,
        payload: filter
    }
}

export const showDotGuiImportModal = (): DotGuiTBTPShowImportModal => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_IMPORT_MODEL
    }
}

export const closeDotGuiImportModal = (): DotGuiTBTPCloseImportModal => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_IMPORT_MODEL
    }
}

export const changeTruong = (payload: number): DotGuiTBTPChangeTruongAction => {
    return {
        type: DotGuiTBTPActionTypeIds.DOTGUITBTP_TRUONG_CHANGED,
        payload: payload
    }
}