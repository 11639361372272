
import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { apiClient } from "./apiClient";

export const SF_HINHTHUCTHANHTOAN_SELECT_ALL = "sf_hinhthucthanhtoan/select_all"
export const SF_HINHTHUCTHANHTOAN_DETAIL = "sf_hinhthucthanhtoan/detail";
export const SF_HINHTHUCTHANHTOAN_INSERT = "sf_hinhthucthanhtoan/insert";
export const SF_HINHTHUCTHANHTOAN_UPDATE = "sf_hinhthucthanhtoan/update";
export const SF_HINHTHUCTHANHTOAN_DELETE = "sf_hinhthucthanhtoan/delete";

export const hinhThucThanhToanApi = {
    select_all: () => apiClient.post(`${SF_HINHTHUCTHANHTOAN_SELECT_ALL}`),
    Detail: (id: number) => apiClient.get(`${SF_HINHTHUCTHANHTOAN_DETAIL}/${id}`),
    Insert: (data: any) => apiClient.post(`${SF_HINHTHUCTHANHTOAN_INSERT}`, data),
    Update: (data: any) => apiClient.post(`${SF_HINHTHUCTHANHTOAN_UPDATE}`, data),
    Delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_HINHTHUCTHANHTOAN_DELETE}`, model)
    }
}