import { sf_mucthuphi_select_nam_nhap_hoc_rq, sf_mucthuphi_select_rq_by_coso } from './../models/request/mucthuphi/sf_mucthiphi_select_rq';
import { sf_mucthuphi_create_salset_rq_nicholas } from './../models/request/mucthuphi/sf_mucthiphi_select_rq';
import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_mucthuphi_coppy_rq } from "../models/request/mucthuphi/sf_mucthiphi_coppy_rq";
import { sf_mucthuphi_select_rq } from "../models/request/mucthuphi/sf_mucthiphi_select_rq";
import { sf_mucthuphi_add_khoannop_rq } from "../models/request/mucthuphi/sf_mucthuphi_add_khoannop_request";
import { sf_mucthuphi_savechanges_request } from "../models/request/mucthuphi/sf_mucthuphi_savechanges_request";
import { sf_mucthuphi } from "../models/response/category/sf_mucthuphi";
import { apiClient } from "./apiClient";
import { NamHocTruongKhoiHeFilter } from '../models/namHocTruongKhoiHeFilter';

export const SF_MUCTHUPHI_SELECT_ALL_API = "sf_mucthuphi/select";
export const SF_MUCTHUPHI_SELECT_BY_NAM_NHAP_HOC_API = "sf_mucthuphi/select_nam_nhap_hoc";
export const SF_MUCTHUPHI_INSERT_API = "sf_mucthuphi/insert";
export const SF_MUCTHUPHI_UPDATE_API = "sf_mucthuphi/update";
export const SF_MUCTHUPHI_DELETE_API = "sf_mucthuphi/delete";
export const SF_MUCTHUPHI_COPPY_API = "sf_mucthuphi/copy";
export const SF_MUCTHUPHI_SAVECHANGES_API = "sf_mucthuphi/savechanges";
export const SF_MUCTHUPHI_ADDKHOANNOP_API = "sf_mucthuphi/add-khoan-nop";
export const SF_MUCTHUPHI_REMOVEKHOANNOP_API = "sf_mucthuphi/remove-khoan-nop";
export const SF_MUCTHUPHI_CREATE_SALESET_API = "sf_mucthuphi/create-saleset";
export const SF_MUCTHUPHI_CREATE_NICOLAS_SALESET_HOCPHI_API = "sf_mucthuphi/create-saleset-hocphi-nicholas";
export const SF_MUCTHUPHI_CREATE_NICOLAS_SALESET_API = "sf_mucthuphi/create-saleset-nicholas";


export const mucThuPhiApi = {
    select: (request: sf_mucthuphi_select_rq) => {
        return apiClient.post(`${SF_MUCTHUPHI_SELECT_ALL_API}`, request)
    },
    select_by_coso: (request: sf_mucthuphi_select_rq_by_coso) => {
        return apiClient.post(`sf_mucthuphi/select_by_coso`, request)
    },
    select_by_nam_nhap_hoc: (request: sf_mucthuphi_select_nam_nhap_hoc_rq) => {
        return apiClient.post(`${SF_MUCTHUPHI_SELECT_BY_NAM_NHAP_HOC_API}`, request)
    },
    insert: (data: sf_mucthuphi) => {
        return apiClient.post(`${SF_MUCTHUPHI_INSERT_API}`, data)
    },
    update: (data: sf_mucthuphi) => {
        return apiClient.post(`${SF_MUCTHUPHI_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_MUCTHUPHI_DELETE_API}`, model)
    },
    coppy: (data: sf_mucthuphi_coppy_rq) => {
        return apiClient.post(`${SF_MUCTHUPHI_COPPY_API}`, data)
    },
    savechanges: (data: sf_mucthuphi_savechanges_request[]) => {
        return apiClient.post(`${SF_MUCTHUPHI_SAVECHANGES_API}`, data)
    },
    addKhoanNop: (data: sf_mucthuphi_add_khoannop_rq) => {
        return apiClient.post(`${SF_MUCTHUPHI_ADDKHOANNOP_API}`, data)
    },
    removeKhoanNop: (data: sf_mucthuphi_add_khoannop_rq) => {
        return apiClient.post(`${SF_MUCTHUPHI_REMOVEKHOANNOP_API}`, data)
    },
    createSaleSet: (data: NamHocTruongKhoiHeFilter) => {
        return apiClient.post(`${SF_MUCTHUPHI_CREATE_SALESET_API}`, data)
    },
    createSaleSet_HocPhi_Nicolas: (data: sf_mucthuphi_create_salset_rq_nicholas) => {
        return apiClient.post(`${SF_MUCTHUPHI_CREATE_NICOLAS_SALESET_HOCPHI_API}`, data)
    },
    createSaleSet_Nicolas: (data: sf_mucthuphi_create_salset_rq_nicholas) => {
        return apiClient.post(`${SF_MUCTHUPHI_CREATE_NICOLAS_SALESET_API}`, data)
    }
}