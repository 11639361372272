
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { dayoffActionTypeIds, DayoffActionTypes } from "../action-types/dayoffActionTypes";
import { dayoffPageState } from "../page-state-model/dayoffPageState";
const initialState: dayoffPageState = {
    sf_dayoff: [],
    sf_dayoff_selected_ids: [],
    status: PageBaseStatus.is_completed,
    sf_dayoff_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}
export const DayoffReducer = (state: dayoffPageState = initialState, action: DayoffActionTypes): dayoffPageState => {
    switch (action.type) {
        case dayoffActionTypeIds.DAYOFF_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case dayoffActionTypeIds.DAYOFF_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_dayoff: action.payload
            }
        case dayoffActionTypeIds.DAYOFF_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case dayoffActionTypeIds.DAYOFF_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_dayoff_editing: action.payload
            }
        case dayoffActionTypeIds.DAYOFF_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                // sf_hinhthucmiengiam_editing: undefined
            }
        case dayoffActionTypeIds.DAYOFF_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case dayoffActionTypeIds.DAYOFF_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case dayoffActionTypeIds.DAYOFF_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case dayoffActionTypeIds.DAYOFF_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công ngày nghỉ ${action.payload.dayoff}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_dayoff_editing: undefined,
                is_show_detail_modal: false
            }
        case dayoffActionTypeIds.DAYOFF_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case dayoffActionTypeIds.DAYOFF_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case dayoffActionTypeIds.DAYOFF_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.ids.length} ngày nghỉ`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case dayoffActionTypeIds.DAYOFF_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case dayoffActionTypeIds.DAYOFF_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_dayoff_selected_ids: action.payload
            }
        default:
            return state;
    }
}