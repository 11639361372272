export enum TongHopHocPhiActionTypeIds {
    TONG_HOP_HOC_PHI_LOAD_START = "TONG_HOP_HOC_PHI_LOAD_START",
    TONG_HOP_HOC_PHI_LOAD_SUCCESS = "TONG_HOP_HOC_PHI_LOAD_SUCCESS",
    TONG_HOP_HOC_PHI_LOAD_ERROR = "TONG_HOP_HOC_PHI_LOAD_ERROR",
    TONG_HOP_HOC_PHI_CHANGE_REQUEST = "TONG_HOP_HOC_PHI_CHANGE_REQUEST"
}

export interface TongHopHocPhiLoadStart {
    type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_START,
    payload: any
}

export interface TongHopHocPhiLoadSucess {
    type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_SUCCESS,
    payload: any[]
}
export interface TongHopHocPhiLoadError {
    type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_ERROR,
    payload: string
}
export interface TongHopHocPhiChangeRequest {
    type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_CHANGE_REQUEST,
    payload: any

}
export type TongHopHocPhiActionTypes = TongHopHocPhiLoadStart | TongHopHocPhiLoadSucess | TongHopHocPhiLoadError | TongHopHocPhiChangeRequest