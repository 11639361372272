import { call, put, takeEvery } from "redux-saga/effects";
import { hocSinhApi } from "../../api/hocSinhApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { TongHopMienGiamActionTypesIds, tongHopHocSinhHasMienGiamStart } from "../action-types/tongHopMienGiamActionTypes";
import { loadHocSinhHasMienGiamError, loadHocSinhHasMienGiamSuccess } from "../actions/tongHopMienGiamActions";

export function* tongHopMienGiamSaga() {
    yield takeEvery(TongHopMienGiamActionTypesIds.TONG_HOP_HOCSINH_HAS_MIEN_GIAM_START, loadTongHopMienGiamStartWorker)
}
function* loadTongHopMienGiamStartWorker(data: tongHopHocSinhHasMienGiamStart) {
    const res: IBaseResponeModel = yield call(hocSinhApi.getForMienGiam, data.payload)
    if (res.is_success) {
        yield put(loadHocSinhHasMienGiamSuccess(res.data))
    } else {
        yield put(loadHocSinhHasMienGiamError(res.message || "Có lỗi"))
    }
}