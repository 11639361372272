import { ganMienGiamHocSinhFilter } from "../../models/request/gan-mien-giam/ganMienGiamHocSinhFilter"
import { ts_hocsinh_viewmodel } from "../../models/response/hocsinh/ts_hocsinh"
import {
    tongHopHocSinhHasMienGiamStart,tongHopHocSinhHasMienGiamSuccess,tongHopHocSinhHasMienGiamError,TongHopMienGiamActionTypesIds
} from "../action-types/tongHopMienGiamActionTypes"

export const loadHocSinhHasMienGiamStart = (rq: ganMienGiamHocSinhFilter): tongHopHocSinhHasMienGiamStart => {
    return {
        type: TongHopMienGiamActionTypesIds.TONG_HOP_HOCSINH_HAS_MIEN_GIAM_START,
        payload: rq
    }
}

export const loadHocSinhHasMienGiamSuccess = (payload: ts_hocsinh_viewmodel[]): tongHopHocSinhHasMienGiamSuccess => {
    return {
        type: TongHopMienGiamActionTypesIds.TONG_HOP_HOCSINH_HAS_MIEN_GIAM_SUCCESS,
        payload: payload
    }
}

export const loadHocSinhHasMienGiamError = (payload: string): tongHopHocSinhHasMienGiamError => {
    return {
        type: TongHopMienGiamActionTypesIds.TONG_HOP_HOCSINH_HAS_MIEN_GIAM_ERROR,
        payload: payload
    }
}