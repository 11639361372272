import { sf_mucthuphi, sf_mucthuphi_viewmodel } from './../../models/response/category/sf_mucthuphi';
import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter";
import { sf_mucthuphi_savechanges_request } from '../../models/request/mucthuphi/sf_mucthuphi_savechanges_request';

export enum MucThuPhiNgsActionTypeIds {
    CHANGE_FILTER = "MUCTHUPHI_NGS_CHANGE_FILTER",

    SHOW_ADD_KHOANNOP_MODAL = "MUCTHUPHI_NGS_SHOW_ADD_KHOANNOP_MODAL",
    CLOSE_ADD_KHOANNOP_MODAL = "MUCTHUPHI_NGS_CLOSE_ADD_KHOANNOP_MODAL",

    SHOW_REMOVE_KHOANNOP_MODAL = "MUCTHUPHI_NGS_SHOW_REMOVE_KHOANNOP_MODAL",
    CLOSE_REMOVE_KHOANNOP_MODAL = "MUCTHUPHI_NGS_CLOSE_REMOVE_KHOANNOP_MODAL",

    LOAD_START = "MUCTHUPHI_NGS_LOAD_START",
    LOAD_SUCCESS = "MUCTHUPHI_NGS_LOAD_SUCCESS",
    LOAD_ERROR = "MUCTHUPHI_NGS_LOAD_ERROR",

    SAVING_START = "MUCTHUPHI_NGS_SAVING_START",
    SAVING_SUCCESS = "MUCTHUPHI_NGS_SAVING_SUCCESS",
    SAVING_ERROR = "MUCTHUPHI_NGS_SAVING_ERROR",

    SHOW_SALESET_CONFIRM = "MUCTHUPHI_NGS_SHOW_SALESET_CONFIRM",
    CLOSE_SALESET_CONFIRM = "MUCTHUPHI_NGS_SHOW_SALESET_CONFIRM",

    CREATE_SALE_SET_START="CREATE_SALE_SET_START",
    CREATE_SALE_SET_SUCCESS="CREATE_SALE_SET_SUCCESS",
    CREATE_SALE_SET_ERROR="CREATE_SALE_SET_ERROR",

    NAMHOC_CHANGED = "NAMHOC_CHANGED",
    TRUONG_CHANGED = "TRUONG_CHANGED",
    KHOI_CHANGED = "KHOI_CHANGED",
    HE_CHANGED = "HE_CHANGED",
}
export interface MucThuPhiNGsChangeFilter {
    type: MucThuPhiNgsActionTypeIds.CHANGE_FILTER,
    payload: NamHocTruongKhoiHeFilter
}
export interface MucThuPhiNgsShowAddKhoanNopModal {
    type: MucThuPhiNgsActionTypeIds.SHOW_ADD_KHOANNOP_MODAL
}
export interface MucThuPhiNgsCloseAddKhoanNopModal {
    type: MucThuPhiNgsActionTypeIds.CLOSE_ADD_KHOANNOP_MODAL
}

export interface MucThuPhiNgsLoadStart {
    type: MucThuPhiNgsActionTypeIds.LOAD_START,
    payload: NamHocTruongKhoiHeFilter
}

export interface MucThuPhiNgsLoadSuccess {
    type: MucThuPhiNgsActionTypeIds.LOAD_SUCCESS,
    payload: sf_mucthuphi_viewmodel[]
}
export interface MucThuPhiNgsLoadError {
    type: MucThuPhiNgsActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface MucThuPhiNgsSavingStart {
    type: MucThuPhiNgsActionTypeIds.SAVING_START,
    payload: sf_mucthuphi_savechanges_request[]
}

export interface MucThuPhiNgsSavingSuccess {
    type: MucThuPhiNgsActionTypeIds.SAVING_SUCCESS,
}
export interface MucThuPhiNgsSavingError {
    type: MucThuPhiNgsActionTypeIds.SAVING_ERROR,
    payload: string
}
export interface MucThuPhiNgsShowRemvoKhoanNopModal {
    type: MucThuPhiNgsActionTypeIds.SHOW_REMOVE_KHOANNOP_MODAL
}
export interface MucThuPhiNgsCloseRemvoKhoanNopModal {
    type: MucThuPhiNgsActionTypeIds.CLOSE_REMOVE_KHOANNOP_MODAL
}

export interface MucThuPhiNgsShowSaleSetConfirm {
    type: MucThuPhiNgsActionTypeIds.SHOW_SALESET_CONFIRM
}
export interface MucThuPhiNgsCloseSaleSetConfirm {
    type: MucThuPhiNgsActionTypeIds.CLOSE_SALESET_CONFIRM
}

export interface  MucThuPhiNgsCreateSaleSetStart{
    type: MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_START,
    payload: NamHocTruongKhoiHeFilter
}

export interface  MucThuPhiNgsCreateSaleSetSuccess{
    type: MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_SUCCESS
}

export interface  MucThuPhiNgsCreateSaleSetError{
    type: MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_ERROR
}

export interface MucThuPhiNgsChangeNamHoc {
    type: MucThuPhiNgsActionTypeIds.NAMHOC_CHANGED,
    payload: string
}

export interface MucThuPhiNgsChangeTruong {
    type: MucThuPhiNgsActionTypeIds.TRUONG_CHANGED,
    payload: number
}

export interface MucThuPhiNgsChangeKhoi {
    type: MucThuPhiNgsActionTypeIds.KHOI_CHANGED,
    payload: number
}

export interface MucThuPhiNgsChangeHe {
    type: MucThuPhiNgsActionTypeIds.HE_CHANGED,
    payload: number
}



export type MucThuPhiNgsActionTypes = MucThuPhiNGsChangeFilter |
    MucThuPhiNgsShowAddKhoanNopModal | MucThuPhiNgsCloseAddKhoanNopModal |
    MucThuPhiNgsLoadStart | MucThuPhiNgsLoadSuccess | MucThuPhiNgsLoadError |
    MucThuPhiNgsSavingStart | MucThuPhiNgsSavingSuccess | MucThuPhiNgsSavingError |
    MucThuPhiNgsShowRemvoKhoanNopModal | MucThuPhiNgsCloseRemvoKhoanNopModal |
    MucThuPhiNgsShowSaleSetConfirm | MucThuPhiNgsCloseSaleSetConfirm |
    MucThuPhiNgsCreateSaleSetStart | MucThuPhiNgsCreateSaleSetSuccess | MucThuPhiNgsCreateSaleSetError |
    MucThuPhiNgsChangeNamHoc | MucThuPhiNgsChangeTruong | MucThuPhiNgsChangeKhoi | MucThuPhiNgsChangeHe