import { ganMienGiamHocSinhFilter } from "../../models/request/gan-mien-giam/ganMienGiamHocSinhFilter";
import { ts_hocsinh_viewmodel } from "../../models/response/hocsinh/ts_hocsinh";

export enum TongHopMienGiamActionTypesIds {
    TONG_HOP_HOCSINH_HAS_MIEN_GIAM_START = "TONG_HOP_HOCSINH_HAS_MIEN_GIAM_START",
    TONG_HOP_HOCSINH_HAS_MIEN_GIAM_SUCCESS = "TONG_HOP_HOCSINH_HAS_MIEN_GIAM_SUCCESS",
    TONG_HOP_HOCSINH_HAS_MIEN_GIAM_ERROR = "TONG_HOP_HOCSINH_HAS_MIEN_GIAM_ERROR",

}

export interface tongHopHocSinhHasMienGiamStart {
    type: TongHopMienGiamActionTypesIds.TONG_HOP_HOCSINH_HAS_MIEN_GIAM_START,
    payload: ganMienGiamHocSinhFilter
}

export interface tongHopHocSinhHasMienGiamSuccess {
    type: TongHopMienGiamActionTypesIds.TONG_HOP_HOCSINH_HAS_MIEN_GIAM_SUCCESS,
    payload: ts_hocsinh_viewmodel[]
}
export interface tongHopHocSinhHasMienGiamError {
    type: TongHopMienGiamActionTypesIds.TONG_HOP_HOCSINH_HAS_MIEN_GIAM_ERROR,
    payload: string
}

export type tongHopMienGiamActionTypes = tongHopHocSinhHasMienGiamStart | tongHopHocSinhHasMienGiamSuccess | tongHopHocSinhHasMienGiamError 