import { call, put, takeEvery } from "redux-saga/effects"
import { dotGuiApi } from "../../api/dotGuiApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { DotGuiActionTypeIds, DotGuiDeleteStartAction, DotGuiLoadStartAction, DotGuiSaveStartAction } from "../action-types/dotGuiActionTypes"
import {
    deleteDotGuiError, deleteDotGuiSuccess, loadDotGuiError, loadDotGuiSuccess,
    saveDotGuiError, saveDotGuiSuccess
} from "../actions/dotGuiActions"
export function* dotGuiSagas(): any {
    yield takeEvery(DotGuiActionTypeIds.DOTGUI_LOAD_START, loadDotGuiWorker)
    yield takeEvery(DotGuiActionTypeIds.DOTGUI_SAVE_START, saveDotGuiWorker)
    yield takeEvery(DotGuiActionTypeIds.DOTGUI_DELETE_START, deleteDotGuiWorker)
}
function* loadDotGuiWorker(data: DotGuiLoadStartAction) {
    const res: IBaseResponeModel = yield call(dotGuiApi.select, data.payload)
    if (res.is_success) {
         yield put(loadDotGuiSuccess(res.data))
    } else {
        yield put(loadDotGuiError(res.message || ""))
    }
}
function* saveDotGuiWorker(data: DotGuiSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(dotGuiApi.update, data.payload)
    } else {
        res = yield call(dotGuiApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveDotGuiSuccess(data.payload))
    } else {
        yield put(saveDotGuiError(res.message || ""))
    }
}
function* deleteDotGuiWorker(data: DotGuiDeleteStartAction) {
    const res: IBaseResponeModel = yield call(dotGuiApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteDotGuiSuccess(data.payload))
    } else {
        yield put(deleteDotGuiError(res.message || ""))
    }
}