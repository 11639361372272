import { NamHocTruongKhoiHeFilter } from './../models/namHocTruongKhoiHeFilter';
import { NamHocCoSoFilter } from './../models/namHocCoSoFilter';
import { sf_saleset_register_dichvu_import_rq } from "../models/request/sale-set/sf_saleset_register_dichvu_import_rq";

import { apiClient } from "./apiClient";


export const SF_SALESET_REGISTER_DICHVU_SELECT = "sf_saleset_register_dichvu/select"
export const SF_SALESET_REGISTER_DICHVU_SELECT_ALL = "register_dichvu/selectall";
export const SF_SALESET_REGISTER_DICHVU_SELECT_BY_COSO = "sf_saleset_register_dichvu/select/by/coso";
export const SF_SALESET_REGISTER_DICHVU_INSERT_MULTIPLE = "sf-saleset-register-dichvu/insert-multiple";
export const SF_SALESET_REGISTER_DICHVU_UPDATE = "sf-saleset-register-dichvu/update";
export const SF_SALESET_REGISTER_DICHVU_INSERTS = "sf-saleset-register-dichvu/inserts";
export const SF_SALESET_REGISTER_DICHVU_INSERT = "sf-saleset-register-dichvu/insert";


export const saleSetDichVuApi = {
    select: (request: NamHocTruongKhoiHeFilter) =>
        apiClient.post(SF_SALESET_REGISTER_DICHVU_SELECT, request),
    select_all: (request: NamHocTruongKhoiHeFilter) =>
        apiClient.post(SF_SALESET_REGISTER_DICHVU_SELECT_ALL, request),
    select_by_coso: (request: NamHocCoSoFilter) =>
        apiClient.post(SF_SALESET_REGISTER_DICHVU_SELECT_BY_COSO, request),
    insert_multiple: (data: any) =>
        apiClient.post(SF_SALESET_REGISTER_DICHVU_INSERT_MULTIPLE, data),
    Update: (data: any) => apiClient.put(`${SF_SALESET_REGISTER_DICHVU_UPDATE}`, data),
    Inserts: (data: any) => apiClient.post(`${SF_SALESET_REGISTER_DICHVU_INSERTS}`, data),
    getSheets: (file_name_uploaded: string) => apiClient.post(`sf-saleset-register-dichvu/get_sheets?file_name_uploaded=${file_name_uploaded}`),
    readFiles: (rq: sf_saleset_register_dichvu_import_rq) => apiClient.post(`sf-saleset-register-dichvu/read_file`, rq),
    import: (rq: sf_saleset_register_dichvu_import_rq) => apiClient.post(`sf-saleset-register-dichvu/import`, rq),
}
