import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_khoannop_group } from "../models/response/category/sf_khoannop_group";
import { apiClient } from "./apiClient";

export const SF_KHOANNOP_GROUP_SELECT_ALL_API = "sf_khoannop_group/select_all";
export const SF_KHOANNOP_GROUP_SELECT_BY_HS_API = "sf-khoannop-group/select-by-hs";
export const SF_KHOANNOP_GROUP_SELECT_KHOANNOP_BY_HS_API = "sf-khoannop-group/select-khoannop-by-hs";
export const SF_KHOANNOP_GROUP_INSERT_API = "sf_khoannop_group/insert";
export const SF_KHOANNOP_GROUP_UPDATE_API = "sf_khoannop_group/update";
export const SF_KHOANNOP_GROUP_DELETE_API = "sf_khoannop_group/delete";


export const khoanNopGroupApi = {
    select_all: () => {
        return apiClient.post(SF_KHOANNOP_GROUP_SELECT_ALL_API)
    },
    select_by_hs: (data: any) => {
        return apiClient.post(SF_KHOANNOP_GROUP_SELECT_BY_HS_API, data)
    },
    select_khoannop_by_hs: (data: any) => {
        return apiClient.post(SF_KHOANNOP_GROUP_SELECT_KHOANNOP_BY_HS_API, data)
    },
    insert: (data: sf_khoannop_group) => {
        return apiClient.post(SF_KHOANNOP_GROUP_INSERT_API, data)
    },
    update: (data: sf_khoannop_group) => {
        return apiClient.post(SF_KHOANNOP_GROUP_UPDATE_API, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(SF_KHOANNOP_GROUP_DELETE_API, model)
    }
}