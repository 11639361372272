import { ganMienGiamHocSinhFilter } from "../models/request/gan-mien-giam/ganMienGiamHocSinhFilter"
import { ts_hocsinh_select } from "../models/request/hocsinh/ts_hocsinh_select_rq"
import { sf_mucphaithu_select_byhocsinh_rq } from "../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq"
import { apiClient } from "./apiClient"

export const TS_HOCSINH_SELECT_API = "ts_hocsinh/select_by"
export const TS_HOCSINH_SEARCH_API = "ts_hocsinh/search"
export const TS_HOCSINH_SELECT_PARENT_EMAIL = "ts_hocsinh/select_suggest_email"
export const hocSinhApi = {
    select: (rq: ts_hocsinh_select) => {
        return apiClient.post(TS_HOCSINH_SELECT_API, rq)
    },
    search: (key: any) => apiClient.post(TS_HOCSINH_SEARCH_API, key),
    selectParentEmail: (ts_hocsinh_id: number) => apiClient.post(`${TS_HOCSINH_SELECT_PARENT_EMAIL}?ts_hocsinh_id=${ts_hocsinh_id}`),
    selectById: (ts_hocsinh_id: number) => apiClient.post(`ts_hocsinh/select_by_id?id=${ts_hocsinh_id}`),
    selectByIdNamHoc: (data: sf_mucphaithu_select_byhocsinh_rq) => apiClient.post(`ts_hocsinh/select_by_id`, data),
    selectTrangThai: () => apiClient.post(`ts_hocsinh/trang-thai-hoc-sinh`),
    getForMienGiam: (formData: ganMienGiamHocSinhFilter) => apiClient.post(`ts_hocsinh/select-for-mien-giam`, formData),
    selectByDotGui: (sf_dotgui_tbtp_id: number) => apiClient.post(`ts_hocsinh/select_by_dotgui?sf_dotgui_tbtp_id=${sf_dotgui_tbtp_id}`),

}
