import { TongHopHocPhiActionTypeIds, TongHopHocPhiChangeRequest, TongHopHocPhiLoadError, TongHopHocPhiLoadStart, TongHopHocPhiLoadSucess } from "../action-types/tongHopHocPhiActionTypes"


export const loadTongHopHocPhiStart = (rq: any): TongHopHocPhiLoadStart => {
    return {
        type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_START,
        payload: rq
    }
}

export const loadTongHopHocPhiSuccess = (payload: any[]): TongHopHocPhiLoadSucess => {
    return {
        type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_SUCCESS,
        payload: payload
    }
}

export const loadTongHopHocPhiError = (payload: string): TongHopHocPhiLoadError => {
    return {
        type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_LOAD_ERROR,
        payload: payload
    }
}


export const changeTongHopHocPhiRequest = (payload: any): TongHopHocPhiChangeRequest => {
    return {
        type: TongHopHocPhiActionTypeIds.TONG_HOP_HOC_PHI_CHANGE_REQUEST,
        payload: payload
    }
}