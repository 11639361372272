import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { DotGuiTBTPActionTypeIds, DotGuiTBTPActionTypes } from "../action-types/dotGuiTBTPActionTypes";
import { DotGuiTBTPPageState } from "../page-state-model/dotGuiTBTPageState";
const initialState: DotGuiTBTPPageState = {
    sf_dotgui_tbtp_viewmodels: [],
    sf_dotgui_tbtp_selected_ids: [],
    status: PageBaseStatus.is_completed,
    filter: {
        nam_hoc: localStorageHelper.getNamHoc(),
        dm_he_id: localStorageHelper.getDmHeId(),
        dm_khoi_id: localStorageHelper.getDmKhoiId(),
        dm_truong_id: localStorageHelper.getDmTruongId()
    },
    nam_hoc: localStorageHelper.getNamHoc(),
    dm_truong_id: localStorageHelper.getDmTruongId()
}
export const dotGuiTBTPReducer = (state: DotGuiTBTPPageState = initialState, action: DotGuiTBTPActionTypes): DotGuiTBTPPageState => {
    switch (action.type) {
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_dotgui_tbtp_viewmodels: action.payload
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_dotgui_tbtp_editing: action.payload
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                sf_dotgui_tbtp_editing: undefined
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công đợt gửi ${action.payload.ten_dot_gui}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_dotgui_tbtp_editing: undefined,
                is_show_detail_modal: false, sf_dotgui_tbtp: action.payload
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} đợt gửi`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_dotgui_tbtp_selected_ids: action.payload
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_SHOW_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: true
            }
        case DotGuiTBTPActionTypeIds.DOTGUITBTP_CLOSE_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: false
            }

        case DotGuiTBTPActionTypeIds.DOTGUITBTP_TRUONG_CHANGED:
            return {
                ...state,
                dm_truong_id: action.payload
            }
        default:
            return state;
    }
}